import React, {useEffect} from 'react';
import P1 from '../img/jo-szczepanska_workshop_wall.jpg';
import P2 from '../img/markus-spiske_dowjones.jpg';
import P3 from '../img/2ppl_collab.jpg';


function AgilePortfolio() {
    useEffect(() => {
        window.initSliders();
    }, []);

    return (

        <section className="section-box ">
            <div className="row">
                <div id="portfolioSlider">
                    <div className="col-lg-4 wp4 ">
                        <div className="col-lg-12 col-lg-12">
                            <div className="card-grid-1 bg-5 hover-up">
                                <div className="overlay-effect effects clearfix">
                                    <div className="img">
                                        <img src={P1} alt="Portfolio Item"/>
                                        <div className="overlay">
                                            <a href="https://form.typeform.com/to/Pmw295"
                                               className="expand"><i
                                                className="fa fa-search"/><br/>Learn
                                                More</a>
                                            <a className="close-overlay hidden">x</a>
                                        </div>
                                        <p className="heading-sm-card color-banduri-blue-900 mt-10"><strong>DESIGN <br/>THINKING</strong>
                                        </p>
                                        <p className="info-sm-card">We use our Change Architecture<sup>®</sup> tools and
                                            methodology to quickly frame
                                            <em><strong> the right problem </strong></em> and design
                                            innovative solutions that get <em><strong>the right
                                                job</strong></em> done <em><strong>the right
                                                way</strong></em>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 wp4 slides">
                        <div className="col-lg-12 col-lg-12">
                            <div className="card-grid-1 bg-9 hover-up">
                                <div className="overlay-effect effects clearfix">
                                    <div className="img">
                                        <img src={P2} alt="Portfolio Item"/>
                                        <div className="overlay">
                                            <a href="https://form.typeform.com/to/DrcqdW"
                                               className="expand"><i
                                                className="fa fa-search"/><br/>Learn
                                                More</a>
                                            <a className="close-overlay hidden">x</a>
                                        </div>
                                        <p className="heading-sm-card color-banduri-blue-900 mt-10"><strong>LEAN
                                            PORTFOLIO <br/>MANAGEMENT</strong></p>
                                        <p className="info-sm-card">We use Lean Portfolio Management to develop and
                                            deploy Portfolio Strategies
                                            that prioritize and align enterprise activities to value-based
                                            objectives to
                                            maximize <em><strong>return on investment</strong></em>.</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4   wp4 slides">
                        <div className="col-lg-12 col-lg-12">
                            <div className="card-grid-1 bg-2 hover-up">
                                <div className="overlay-effect effects clearfix">
                                    <div className="img">
                                        <img src={P3} alt="Portfolio Item"/>
                                        <div className="overlay">
                                            <a href="https://form.typeform.com/to/UbpuxL"
                                               className="expand"><i
                                                className="fa fa-search"/><br/>Learn
                                                More</a>
                                            <a className="close-overlay hidden">x</a>
                                        </div>
                                        <p className="heading-sm-card color-banduri-blue-900 mt-10">
                                            <strong>VALUE-DRIVEN <br/>DELIVERY</strong>
                                        </p>
                                        <p className="info-sm-card">We implement a data-informed approach for delivery
                                            that
                                            allows you to make the best use of resources and adapt responsively
                                            to
                                            change <em><strong>without
                                                compromising value</strong></em>.</p>
                                        <p><br/></p>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

            </div>
        </section>


    )
        ;
}

export default AgilePortfolio;
