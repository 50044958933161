import React, {useEffect} from 'react';
import P1 from '../img/felix-mittermeier-bw_chessboard.jpg';
import P2 from '../img/jacek-dylag-streetwalk.jpg';
import P3 from '../img/adrien-mobilephone.jpg';

function DesignPortfolio() {
    useEffect(() => {
        window.initSliders();
    }, []);

    return (
        <section className="section-box ">
            <div className="row">
                <div id="portfolioSlider">
                    <div className="col-lg-4 wp4 slides">
                        <div className="col-lg-12 col-lg-12">
                            <div className="card-grid-1 bg-5 hover-up">
                                <div className="overlay-effect effects clearfix">
                                    <div className="img">
                                        <img src={P1} alt="Portfolio Item"/>
                                        <div className="overlay">
                                            <a href="https://form.typeform.com/to/Pmw295"
                                               className="expand"><i
                                                className="fa fa-search"/><br/>Learn
                                                More</a>
                                            <a className="close-overlay hidden">x</a>
                                        </div>
                                        <p className="heading-sm-card color-banduri-blue-900 mt-10"><strong>STRATEGIC
                                            DESIGN <br/> & INNOVATION</strong>
                                        </p>
                                        <p className="info-sm-card"> We use futures-thinking and foresight principles to
                                            design strategies that increase your ability to<em><strong> execute</strong>
                                            </em> and deliver <em><strong>measurable
                                                value
                                            </strong></em>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 wp4 slides">
                        <div className="col-lg-12 col-lg-12">
                            <div className="card-grid-1 bg-9 hover-up">
                                <div className="overlay-effect effects clearfix">
                                    <div className="img">
                                        <img src={P2} alt="Portfolio Item"/>
                                        <div className="overlay">
                                            <a href="https://form.typeform.com/to/DrcqdW"
                                               className="expand"><i
                                                className="fa fa-search"/><br/>Learn
                                                More</a>
                                            <a className="close-overlay hidden">x</a>
                                        </div>
                                        <p className="heading-sm-card color-banduri-blue-900 mt-10">
                                            <strong>ORGANIZATIONAL <br/>DESIGN</strong>
                                        </p>
                                        <p className="info-sm-card">Using Design Thinking and Service Design, we help
                                            you
                                            quickly identify business operations models that <em><strong>optimize for
                                                value</strong></em>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4   wp4 slides">
                        <div className="col-lg-12 col-lg-12">
                            <div className="card-grid-1 bg-2 hover-up">
                                <div className="overlay-effect effects clearfix">
                                    <div className="img">
                                        <img src={P3} alt="Portfolio Item"/>
                                        <div className="overlay">
                                            <a href="https://form.typeform.com/to/UbpuxL"
                                               className="expand"><i
                                                className="fa fa-search"/><br/>Learn
                                                More</a>
                                            <a className="close-overlay hidden">x</a>
                                        </div>
                                        <p className="heading-sm-card color-banduri-blue-900 mt-10">
                                            <strong>PRODUCT <br/>DESIGN</strong></p>
                                        <p className="info-sm-card">We help organizations design products and services
                                            that people want
                                            to
                                            use because they solve <em><strong>problems that matter</strong></em>.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default DesignPortfolio;
