import React from 'react';



function Contact() {
    const url = 'https://banduri.us4.list-manage.com/subscribe/post?u=da5e362853867719883eda526&amp;id=7ee4db5225&amp;f_id=000355e8f0';

    return (
        <div>
            <section id="contactInfo" className="section-box">
                <div className="banner-hero banner-breadcrums">
                    <div className="container text-center">
                        <h1 className="text-heading-1 color-banduri-blue-900 mb-10">Say <strong> <span
                            className="color-orange-900">hello</span></strong></h1>

                    </div>
                </div>
            </section>

            <section className="section-box">
                <div className="container mb-5 mt-60">
                    <div className="bdrd-58 box-gray-100 icon-wave">
                        <div className="row">
                            <div className="col-lg-12 mb-10"><span
                                className="text-body-capitalized text-uppercase">Contact us</span>
                                <h2 className="text-heading-3 color-banduri-blue-900 mt-10">Let's Connect</h2>
                                <p className="text-body-text color-gray-600 mt-20">Let us help you harness the
                                    power of
                                    change <br
                                        className="d-lg-block d-none"/> to unlock your greatest
                                    potential.
                                </p>
                            </div>
                            <div className="col-lg-4 mb-20">
                                <h4 className="text-heading-6 color-banduri-blue-900 icon-home mb-10 mt-10">Banduri </h4>
                                <p className="text-body-text color-banduri-blue-900"><i
                                    className="fa fa-map-marker"/> 400
                                    Charles Street <br/>Fredericksburg,
                                    Virginia 22401
                                </p>
                                <p className="text-body-text color-banduri-blue-900"><i className="fa fa-mobile"/> (540)
                                    699-0805</p>
                                <p className="text-body-text color-banduri-blue-900"><i className="fa fa-envelope "/> <a
                                    href="mailto:hello@banduri.com">hello@banduri.com</a></p>
                            </div>
                            {/* //add mailchimp codes here*/}
                            <div id="mc_embed_signup">
                                <form
                                    action="https://banduri.us4.list-manage.com/subscribe/post?u=da5e362853867719883eda526&amp;id=7ee4db5225&amp;f_id=000355e8f0"
                                    method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
                                    className="validate" target="_blank" noValidate>
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-4 mc-field-group">
                                                <div className=" form-group  "><input className="form-control"
                                                                                      id="mce-FNAME"
                                                                                      type="text"
                                                                                      name="FNAME"
                                                                                      placeholder="Enter your full name"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mc-field-group">
                                                <div className="form-group "><input className="form-control"
                                                                                    id="mce-EMAIL"
                                                                                    type="email"
                                                                                    name="EMAIL"
                                                                                    placeholder="Enter your email"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mc-field-group size1of2">
                                                <div className="form-group"><input className="form-control"
                                                                                   id="mce-PHONE"
                                                                                   type="text"
                                                                                   name="PHONE"
                                                                                   placeholder="Enter your phone number"/>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 mc-field-group">
                                                <div className="form-group"><textarea className="form-control"
                                                                                      id="mce-MMERGE2"
                                                                                      type="text"
                                                                                      name="MMERGE2"
                                                                                      placeholder="Drop us a line!"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mt-15">
                                                <button className="btn btn-black icon-arrow-right-white mr-40 mb-20"
                                                        type="submit">Send
                                                    Message
                                                </button>

                                                <br className="d-lg-none d-block"/><span
                                                className="text-body-text-md color-gray-500 mb-5">By clicking the Send Message button, you agree to our
                                                                           <a href="/terms"> Terms and Conditions.</a></span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default Contact;
