import React, {Component} from 'react';

import NotFound from './NotFound';
import P1 from './img/daniel-kempe-kid-fairy.jpg'

class ErrorHandler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorOccurred: false,
            errorText: ''
        }
    }

    componentDidCatch(error) {
        this.setState({errorOccurred: true, errorText: error});
    }

    render() {
        return this.state.errorOccurred ? (

            <NotFound/>
            /* <div className="Error">
                 <div className="content">
                     <h2>BRB! We ran out of pixie dust!</h2>
                     <p>We&apos;re experiencing an issue with the page you&apos;ve requested. We apologize for the
                         inconvenience.</p>
                     <div className="img">
                         <img src={P1} alt="Portfolio Item"/>
                     </div>
                     <p><a href="/">Return Home</a></p>
                 </div>
             </div>*/
        ) : this.props.children
    }
}

export default ErrorHandler;