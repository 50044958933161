import React, {useEffect} from 'react';
import Footer from "../elements/Footer";
import Contact from "../elements/Contact";
import ReactGA from 'react-ga';
import DevelopmentPortfolio from "../elements/DevelopmentPortfolio";
import Accelerate from "../elements/Accelerate";
import IntHeader from "../elements/IntHeader";

function Development() {
    useEffect(() => {
        ReactGA.initialize('UA-148043567-1');
        ReactGA.pageview('/development');
        ReactGA.event({
            category: 'Visitor',
            action: 'view development info'
        });

        let qs, js, q, s, d = document,
            gi = d.getElementById,
            ce = d.createElement,
            gt = d.getElementsByTagName,
            id = "typeform",
            b = "https://embed.typeform.com/";

        if (!gi.call(d, id)) {
            js = ce.call(d, "script");
            js.id = id;
            js.src = b + "embed.js";
            q = gt.call(d, "script")[0];
            q.parentNode.insertBefore(js, q);
        }
    }, []);

    return (
        <div id="Development">
            <IntHeader/>
            <section className="section-box">
                <div className="banner-hero banner-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10">
                                <h1 className="text-display-2 color-banduri-blue-900">
                                    Continuous value requires continuous
                                    <span className="color-orange-900"> delivery</span>
                                </h1>


                                <p className="text-body-lead-large color-gray-500 mt-10 pr-40">
                                    We apply expertise in leading-edge technology, Agile,
                                    Engineering, and Human-Centered Design to develop services and products that solve
                                    real problems,
                                    really
                                    fast.
                                </p>
                                <div className="mt-20">
                                    <a href="https://form.typeform.com/to/S4cdtS"
                                       className="btn btn-black icon-arrow-right-white mb-20">Get More Info
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <DevelopmentPortfolio/>
            <div className="col-lg-12">

            </div>

            <Footer/>
        </div>

    );
}

export default Development;