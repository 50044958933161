import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import BanduriLogo from '../img/Banduri_Yet_Logo_300H.png';


import {
    faAtom,
    faComments,
    faHandshake,
    faMedal,
    faObjectGroup,
    faTools,
    faUsers
} from '@fortawesome/free-solid-svg-icons'

function Header() {
    const initialState = {navActive: true};
    const [state, setState] = useState(initialState);

    return (

        <header id="home">
            <nav>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 col-xs-8 col-xs-offset-2">
                            <nav className={`pull${state.navActive ? ' active' : ''}`}>
                                <ul className="top-nav">


                                    <li><a href="/Team">
                                        <FontAwesomeIcon icon={faUsers}/>
                                        About <span className="indicator"><i
                                        className="fa fa-angle-right"/></span></a>
                                    </li>

                                    <li><a href="Jobs">
                                        <FontAwesomeIcon icon={faHandshake}/>
                                        Jobs <span className="indicator"><i
                                        className="fa fa-angle-right"/></span></a>
                                    </li>

                                    <li><a href="/Contracts">
                                        <FontAwesomeIcon icon={faMedal}/>
                                        Contracts <span className="indicator"><i
                                        className="fa fa-angle-right"/></span></a></li>

                                    <li><a href="/Agile">
                                        <FontAwesomeIcon icon={faTools}/>
                                        Agile <span className="indicator"><i
                                        className="fa fa-angle-right"/></span></a></li>
                                    <li><a href="/Design">
                                        <FontAwesomeIcon icon={faObjectGroup}/>
                                        Design <span className="indicator"><i
                                        className="fa fa-angle-right"/></span></a></li>
                                    <li><a href="/Development">
                                        <FontAwesomeIcon icon={faAtom}/>
                                        Development <span className="indicator"><i
                                        className="fa fa-angle-right"/></span></a>
                                    </li>
                                    <li><a href="/Contact">
                                        <FontAwesomeIcon icon={faComments}/>
                                        Contact Us <span className="indicator"><i
                                        className="fa fa-angle-right"/></span></a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </nav>
            <section className="hero" id="hero">
                <div className="homeContainer">
                    <div className="row">
                        <div className={`col-md-12 text-right navicon${state.navActive ? ' active' : ''}`}>
                            <a href="/">
                                <svg version="1.1" id="Layer_1" x="0px" y="0px"
                                     width="329px" height="120px" viewBox="0 0 329 120"
                                     enable-background="new 0 0 329 120">
                                    <g>
                                        <path fill="#0E113D" className="text" d="M53.412,30.875h7.526v14.256v6.224c3.618-5.138,9.408-7.381,14.473-7.454
                                        c10.204,0,19.973,7.599,19.973,20.697c0,12.665-8.973,20.986-20.263,20.986c-6.729,0-11.94-3.256-14.472-7.96v6.803h-7.237V30.875z
                                         M87.858,64.67c0-7.671-5.862-13.749-13.605-13.749c-8.104,0-13.604,6.296-13.604,13.749c0,7.526,5.355,13.894,13.387,13.894
                                        c8.973,0,13.75-7.019,13.822-13.821V64.67z"/>
                                        <path fill="#0E113D" className="text" d="M142.996,84.427h-7.525v-6.875c-2.75,5.283-8.395,8.032-14.618,8.032
                                        c-12.665,0-20.191-9.842-20.191-20.841c0-12.012,8.757-20.841,20.191-20.841c7.815,0,12.808,4.342,14.618,8.105V45.06h7.525V84.427
                                        z M108.188,64.888c0.073,5.862,4.197,13.677,13.75,13.677c6.007,0,10.276-3.328,12.229-7.815c0.869-1.809,1.304-3.836,1.375-5.862
                                        c0.073-2.026-0.289-4.052-1.157-5.861c-1.882-4.56-6.295-8.105-12.592-8.105c-8.249,0-13.605,6.512-13.605,13.894V64.888z"/>
                                        <path fill="#0E113D" className="text" d="M152.906,45.06h7.092v6.079c1.882-4.053,6.296-7.309,13.315-7.237
                                        c12.592,0.145,15.921,9.263,15.921,17.44v23.085h-7.6V63.079c0-7.092-2.75-12.157-10.42-12.085
                                        c-7.381,0.072-10.783,4.704-10.783,11.506v21.927h-7.526V45.06z"/>
                                        <path fill="#0E113D" className="text" d="M238.801,84.427h-7.237v-6.875c-2.17,4.053-6.585,8.032-14.689,8.032
                                        c-12.375,0-20.19-9.552-20.19-20.914c0-12.229,8.828-20.769,20.046-20.769c8.032,0,12.953,4.488,14.545,7.599v-6.947V30.875h7.526
                                        V84.427z M204.21,64.743c0.072,7.236,5.138,13.821,13.821,13.821c3.836,0,7.382-1.592,9.843-4.124
                                        c2.532-2.533,3.979-6.007,3.835-9.914c0-3.908-1.521-7.309-3.98-9.697c-2.461-2.461-5.861-3.908-9.697-3.908
                                        c-8.322,0-13.821,6.368-13.821,13.749V64.743z"/>
                                        <path fill="#0E113D" className="text" d="M285.038,84.427h-7.092v-6.079c-1.882,4.053-6.296,7.31-13.315,7.236
                                        c-12.664-0.072-15.92-9.262-15.92-17.44V45.06h7.525v21.348c0,7.091,2.75,12.157,10.494,12.157
                                        c7.381-0.071,10.781-4.775,10.781-11.506V45.06h7.526V84.427z"/>
                                        <path fill="#0E113D" className="text" d="M294.876,45.06h7.092v5.789c2.243-4.631,6.368-6.874,11.724-6.947v7.526h-0.507
                                        c-7.092,0-10.782,3.908-10.782,11.723v21.276h-7.526V45.06z"/>
                                        <path fill="#0E113D" className="text" d="M319.623,30.876h7.524v7.96h-7.524V30.876z M319.623,45.06h7.524v39.367h-7.524
                                        V45.06z"/>
                                        <path fill="#F26522" d="M16.771,1c0,0-17.081,12.872-14.507,46.34c0.4,5.195,2.772,12.439,8.079,19.655
                                        c4.178,5.68,6.831,13.867,6.548,18.174c-0.328,4.991,7.84-13.12,3.736-26.316C12.922,34.073,11.2,18.999,16.771,1"/>
                                        <path fill="#F26522" d="M19.655,21.57c0,0,4.368,9.282,8.9,16.284
                                        c4.703,7.265,12.248,29.701-5.5,43.526c-0.643,0.501-1.409,0.898-1.409,0.898c-0.029-0.031,0.32-0.457,0.675-1.073
                                        c0.944-1.644,5.179-11.995,2.806-25.138C22.737,42.825,17.835,37.212,19.655,21.57"/>
                                        <path fill="#F26522" d="M27.825,108.777c-9.814,12.642-19.839,8.784-19.839,8.784
                                        c-9.642-23.355,16.713-32.997,16.713-32.997C35.198,97.85,27.825,108.777,27.825,108.777 M23.368,95.126
                                        c0,0-5.729,0.644-5.515,7.929C17.853,103.055,23.954,102.841,23.368,95.126"/>
                                    </g>
                                </svg>
                            </a>
                            <button type="button" id="nav-toggle"
                                    className={`nav_slide_button${state.navActive ? ' active' : ''}`}
                                    onClick={() => state.navActive ? setState({navActive: false}) : setState({navActive: true})}>
                                <span/></button>
                        </div>

                    </div>
                    <div className="container ">
                        <div className="col-md-8 col-md-offset-2 text-center inner">
                            {/*<h1 className="animated fadeInDown"><span>BANDURI</span></h1>*/}
                            <img className="img-responsive center" src={BanduriLogo}/>
                            {/*<p className="animated fadeInUp delay-05s">Designing for Change<sup>®</sup></p>*/}
                            <h2 className="changeStatement" align="center"> We Turn Change Into <span
                                className="color-orange-900"> <br/>Value.</span></h2>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 text-center">
                            <div className="row">
                                <div className="row text-center">
                                    <a data-scroll href="#intro">
                                        <div className="navarrow"></div>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </header>

    );
}

export default Header;

