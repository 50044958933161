import React from 'react';
import IntHeader from "../elements/IntHeader";
import Footer from "../elements/Footer";
import Portfolio from "../elements/Portfolio";
import Contact from "../elements/Contact";
import ReactGA from 'react-ga';

function Resources() {

    ReactGA.initialize('UA-148043567-1');
    ReactGA.pageview('/resources');
    ReactGA.event({
        category: 'Visitor',
        action: 'view resources'
    });
    return (
        <div id="Resources">
            <IntHeader/>
            <div className="content container">
                <h1 className="arrow text-center">Resources</h1>
                Page Content
            </div>
            <Portfolio/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default Resources;