import P1 from '../img/template/404.png';
import React from 'react';
import IntHeader from "../elements/IntHeader";


function Error() {
    return (
        <div id="errorPage">
            <section className="section-box mt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center mt-40">
                            <img className="img-responsive" src={P1} alt="Banduri"/>
                            <h2 className="text-heading-1 color-gray-900 mb-20 mt-50">
                                Whoops! That page doesn’t exist.
                            </h2>
                            <p className="text-heading-5 color-gray-600 mt-30 mb-70">
                                The page you requested could not be found
                            </p>
                            <div className="text-center mb-50">
                                <a href="/">
                                    <a className="btn btn-black icon-arrow-left">Return to Homepage</a>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Error;