import React from 'react';
import Header from "../elements/Header";
import Hook from "../elements/Hook";
import Features from "../elements/Features";
import Portfolio from "../elements/Portfolio";
import Contact from "../elements/Contact";
import Footer from "../elements/Footer";
import Subscribe from "../elements/Subscribe";
import Terms from "./Terms";
import ReactGA from 'react-ga';

function Home() {
    ReactGA.initialize('UA-148043567-1');
    ReactGA.pageview('/home');
    ReactGA.event({
        category: 'Visitor',
        action: 'Take survey'
    });
    return (
        <div id="Home">
            <Header/>
            <Hook/>
            <Portfolio/>
            <Features/>
            <Subscribe/>
            <Contact/>
            <Footer/>
        </div>
    );

}

export default Home;