import React from 'react';
import Capabilities from "../elements/Capabilities";
import Footer from "../elements/Footer";
import ReactGA from 'react-ga';
import IntHeader from "../elements/IntHeader";

function Contracts() {
    ReactGA.initialize('UA-148043567-1');
    ReactGA.pageview('/home');
    ReactGA.event({
        category: 'Visitor',
        action: 'View Contract Info'
    });
    return (
        <div id="Contracts">
            <IntHeader/>
            <Capabilities/>
            <Footer/>
        </div>
    );

}

export default Contracts;