import React from 'react';
import IntHeader from "../elements/IntHeader";
import Footer from "../elements/Footer";
import Contact from "../elements/Contact";
import ReactGA from 'react-ga';

function Events() {

    ReactGA.initialize('UA-148043567-1');
    ReactGA.pageview('/events');
    ReactGA.event({
        category: 'Visitor',
        action: 'View events'
    });
    return (
        <div id="Events">
            <IntHeader/>
            <div className="content container">
                <h1 className="arrow text-center">Events</h1>
                Page Content
            </div>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default Events;