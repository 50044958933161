import React, {useEffect} from 'react';
import P1 from '../img/IMG_3340.jpg';
import P2 from '../img/evangeline-shaw-touchscreen.jpg';
import P3 from '../img/new-data-services-BPR.jpg';

function DevelopmentPortfolio() {
    useEffect(() => {
        window.initSliders();
    }, []);

    return (
        <div id="devPortfolio">
            <section className="section-box mt-100">
                <div className="container">
                    <div className="text-center mb-20"><span
                        className="tag-1 bg-6 color-green-900">What We Do, What You Get</span></div>
                    <div className="row">
                        <div className="col-lg-2 col-sm-1 col-12"></div>
                        <div className="col-lg-8 col-sm-10 col-12 text-center">
                            <h2 className="text-heading-1 color-banduri-blue-900">We bring the world&apos;s<br
                                className="d-lg-block d-none"/> ideas to life</h2>
                            <p className="text-body-lead-large color-gray-600 mt-20">We apply decades of experience
                                in
                                Design, Agile, and Engineering to every problem.</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="bd-bottom pb-20 mb-10 text-mb-center">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-6 col-6 mb-30"><span
                                    className="text-display-3 color-green-900">100<span
                                    className="count">%</span></span>
                                    <p className="text-body-text color-banduri-blue-900 pl-40">Cleared <br/>Personnel
                                    </p>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-6 mb-30"><span
                                    className="text-display-3 color-green-900">20<span
                                    className="count">+</span></span>
                                    <p className="text-body-text color-banduri-blue-900 pl-40">Years Engineering
                                        Experience</p>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-6 mb-30"><span
                                    className="text-display-3 color-green-900">80<span
                                    className="count">%</span></span>
                                    <p className="text-body-text color-banduri-blue-900 pl-40"> Engineers with Master's
                                        Degree
                                        or
                                        Higher</p>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-6 mb-30"><span
                                    className="text-display-3 color-green-900">10<span
                                    className="count">+</span></span>
                                    <p className="text-body-text color-banduri-blue-900 pl-40">Years of Cloud
                                        Development</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-box ">
                <div className="row">
                    <div id="portfolioSlider">
                        <div className="col-lg-4 wp4 slides">
                            <div className="col-lg-12 col-lg-12">
                                <div className="card-grid-1 bg-5 hover-up">
                                    <div className="overlay-effect effects clearfix">
                                        <div className="img">
                                            <img src={P1} alt="Portfolio Item"/>
                                            <div className="overlay">
                                                <a href="https://form.typeform.com/to/xnGTPS" className="expand"><i
                                                    className="fa fa-search"/><br/>Learn
                                                    More</a>
                                                <a className="close-overlay hidden">x</a>
                                            </div>
                                            <p className="heading-sm-card color-banduri-blue-900 mt-10"><strong>ENTERPRISE
                                                ARCHITECTURE<br/>
                                                & CLOUD ENGINEERING</strong></p>
                                            <p className="info-sm-card">Organizations that stay ahead of <em><strong>pacing
                                                threats</strong></em> need agile
                                                architecture. We work with a wide array of customers across industry and
                                                government to
                                                develop enterprise architectures that
                                                outpace<em><strong> change</strong></em>.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 wp4 slides">
                            <div className="col-lg-12 col-lg-12">
                                <div className="card-grid-1 bg-9 hover-up">
                                    <div className="overlay-effect effects clearfix">
                                        <div className="img">
                                            <img src={P2} alt="Portfolio Item"/>
                                            <div className="overlay">
                                                <a href="https://form.typeform.com/to/S4cdtS" className="expand"><i
                                                    className="fa fa-search"/><br/>Learn
                                                    More</a>
                                                <a className="close-overlay hidden">x</a>
                                            </div>

                                            <p className="heading-sm-card color-banduri-blue-900 mt-10">
                                                <strong>PRODUCT <br/> DEVELOPMENT</strong>
                                            </p>
                                            <p className="info-sm-card">Exceptional products
                                                have <em><strong> frictionless user
                                                    experiences</strong></em>, are
                                                always
                                                available, and provide insights into user needs. We bring decades of
                                                product
                                                development experience at all scales to every solution we develop.</p>
                                            <p><br/></p>
                                            <p><br/></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4   wp4 slides">
                            <div className="col-lg-12 col-lg-12">
                                <div className="card-grid-1 bg-2 hover-up">
                                    <div className="overlay-effect effects clearfix">
                                        <div className="img">
                                            <img src={P3} alt="Portfolio Item"/>
                                            <div className="overlay">
                                                <a href="https://form.typeform.com/to/zUWRg5" className="expand"><i
                                                    className="fa fa-search"/><br/>Learn
                                                    More</a>
                                                <a className="close-overlay hidden">x</a>
                                            </div>
                                            <p className="heading-sm-card color-banduri-blue-900 mt-10"><strong>BUSINESS<br/> PROCESS
                                                ENGINEERING</strong></p>
                                            <p className="info-sm-card">We use our expertise in <em> <strong>human-centered
                                                design</strong></em>,
                                                technology, agile, and
                                                business development to reengineer business processes that are aligned
                                                to
                                                operational activities and create <em><strong>measurable value</strong></em>.
                                            </p>
                                            <p><br/></p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>


    );
}

export default DevelopmentPortfolio;