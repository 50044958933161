import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faCalendarAlt,
    faMagic,
    faMapMarkedAlt,
    faPlug,
    faRulerCombined,
    faChartLine
} from '@fortawesome/free-solid-svg-icons'
import CapStmnt from "../cap_stmnt/Banduri_CapStatement_DEC22.pdf";

function Features() {
    return (

        <React.Fragment>
            <section className="section-box">
                <div className="banner-hero banner-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <h1 className="text-display-2 color-banduri-blue-900">
                                    Solving big problems takes courage and
                                    <span className="color-orange-900"> creativity</span>
                                </h1>
                                <p className="text-body-lead-large color-gray-500 mt-10 pr-40">
                                    Our Change Architecture<sup>®</sup> tools and methodology are simple and impactful.
                                </p>
                                <div className="mt-20">
                                    <a href={CapStmnt} className="btn btn-black icon-arrow-right-white mb-20">Get our
                                        Capabilities Statement
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="features text-center section-padding" id="features">
                <div className="container ">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="arrow text-center">How We Work</h1>
                            <p className="text-body-lead-large text-center color-gray-500 mt-10 pr-40">
                                We combine 20 years of Agile best practices,
                                human-centered design, and engineering to align people,
                                process,
                                and
                                technology to value-driven business and mission objectives.
                            </p>
                            <div className="container">
                                <div className="features-wrapper">
                                    <div className="col-md-2 wp2">
                                        <div className="icon">
                                            <FontAwesomeIcon icon={faRulerCombined}/>
                                        </div>
                                        <p className="text-body-lead-large color-gray-500 mt-10 pr-20">Frame The
                                            Problem</p>
                                    </div>
                                    <div className="col-md-2 wp2 delay-05s">
                                        <div className="icon">
                                            <FontAwesomeIcon icon={faMapMarkedAlt}/>
                                        </div>
                                        <p className="text-body-lead-large color-gray-500 mt-10 pr-20">Map The
                                            Journey</p>
                                    </div>
                                    <div className="col-md-2 wp2 delay-75s">
                                        <div className="icon">
                                            <FontAwesomeIcon icon={faMagic}/>
                                        </div>
                                        <p className="text-body-lead-large color-gray-500 mt-10 pr-20">Design The
                                            Solution</p>
                                    </div>
                                    <div className="col-md-2 wp2 delay-1s">
                                        <div className="icon">
                                            <FontAwesomeIcon icon={faPlug}/>
                                        </div>
                                        <p className="text-body-lead-large color-gray-500 mt-10 pr-20">Validate Solution
                                            Fit</p>
                                    </div>
                                    <div className="col-md-2 wp2 delay-125s">
                                        <div className="icon">
                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                        </div>
                                        <p className="text-body-lead-large color-gray-500 mt-10 pr-20">Plan
                                            Execution</p>
                                    </div>
                                    <div className="col-md-2 wp2 delay-125s">
                                        <div className="icon">
                                            <FontAwesomeIcon icon={faChartLine}/>
                                        </div>
                                        <p className="text-body-lead-large color-gray-500 mt-10 pr-20">Measure Value</p>
                                    </div>
                                    <div className="clearfix"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3 text-center">
                                {/*  <a href="https://banduri.typeform.com/to/MXexfK" className="learn-more-btn">Request Info</a>*/}
                                <div className="row">
                                    <div className="row text-center">
                                        <a data-scroll href="#contactInfo">
                                            <div className="navarrow"></div>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    );
}

export default Features;
