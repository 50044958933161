import React, {useEffect} from 'react';
import IntHeader from "../elements/IntHeader";
import Footer from "../elements/Footer";
import Contact from "../elements/Contact";
import ReactGA from 'react-ga';
import Portfolio from "../elements/Portfolio";

function Products() {
    useEffect(() => {
        ReactGA.initialize('UA-148043567-1');
        ReactGA.pageview('/products');
        ReactGA.event({
            category: 'Visitor',
            action: 'view product info'
        });

        let qs, js, q, s, d = document,
            gi = d.getElementById,
            ce = d.createElement,
            gt = d.getElementsByTagName,
            id = "typeform",
            b = "https://embed.typeform.com/";

        if (!gi.call(d, id)) {
            js = ce.call(d, "script");
            js.id = id;
            js.src = b + "embed.js";
            q = gt.call(d, "script")[0];
            q.parentNode.insertBefore(js, q);
        }
    }, []);

    return (
        <div id="Products">
            <IntHeader/>
            <div className="content container">
                <h1 className="arrow text-center">Products</h1>
                Page Content
            </div>
            <Portfolio/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default Products;