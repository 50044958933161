import React, {useEffect, useState} from 'react';
import IntHeader from "../elements/IntHeader";
import Footer from "../elements/Footer";
//import Features from "../elements/Features";
//import Swag from "../elements/Swag";
import Contact from "../elements/Contact";
import ReactGA from 'react-ga';


function Blog() {
    const mediumURL = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40banduri';
    const initialState = {data: []};
    const [state, setState] = useState(initialState);
    useEffect(() => {
        fetch(mediumURL).then(rsp => rsp.json()).then(data => {

            const posts = [];
            console.log("this is data: " + data);
            const json = JSON.stringify(data); //This is an array with the content. No feed, no info about author etc..
            console.log("this is json: " + json);
            const result = JSON.parse(json);
            const url = result.url;
            const image = result.image;


            setState({data: posts});
        }).catch(e => console.log(`Error: ${e}`));
    }, []);

    ReactGA.initialize('UA-148043567-1');
    ReactGA.pageview('/thinking');
    ReactGA.event({
        category: 'Visitor',
        action: 'view thinking'
    });

    return (
        <div id="Thinking">
            <IntHeader/>
            <div className="content container blog">
                <h1 className="arrow text-center hide">Thinking</h1>
                {state.data}
            </div>
            {/*  <Swag />
            <Features />*/}
            <Contact/>
            <Footer/>
        </div>
    );
}

export default Blog;