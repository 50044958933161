import React, {useState} from 'react';

import Design from '../img/page/homepage1/business-strategy.svg';
import Hiring from '../img/page/homepage4/banner.png';
import Hiring2 from '../img/page/homepage3/img-2.png';
import Local from '../img/page/homepage1/local.svg';
import Compensation from '../img/page/homepage1/social.svg';
import BanduriOrgLogo from '../img/banduri-social-profile-inverted.jpg';
import Banner1 from '../img/page/services/1/icon-business.svg';
import Banner2 from '../img/page/services/1/banner.png';
import Built from '../img/page/homepage2/img-built.png';
import Built2 from '../img/page/homepage2/img-built-2.png';

function JobListing() {
    // Open modal
    const [modal, setModal] = useState(false);
    const [videoLoading, setVideoLoading] = useState(true);
    const openModal = () => {
        setModal(!modal);
    };
    const spinner = () => {
        setVideoLoading(!videoLoading);
    };
    return (
        <div id="jobListings">
            <div id="teams">
                <section className="section-box">
                    <div className="banner-hero bg-service-1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <h1 className="text-display-2 color-banduri-blue-900">
                                        Be part of a high performing
                                        <span className="color-orange-900"> team</span>
                                    </h1>
                                    <p className="text-body-lead-large color-gray-500 mt-10 pr-40">
                                        Great people deserve to be part of exceptional teams.
                                    </p>
                                    <div className="mt-20">
                                        <a href="#openings"
                                           className="btn btn-black icon-arrow-right-white mb-20">Search Openings
                                        </a>
                                    </div>

                                    {/* <div className="row mt-50">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div className="list-icons">
                                                <div className="item-icon none-bd"><span
                                                    className="icon-left"><img
                                                    src={Project} alt="Banduri"/></span>
                                                    <h4 className="text-heading-4"><span
                                                        className="text-heading-3 color-green-900">98<span
                                                        className="count">%</span></span></h4>
                                                    <p className="text-body-text color-gray-500">Employee
                                                        Satisfaction</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div className="list-icons">
                                                <div className="list-icons">
                                                    <div className="item-icon none-bd"><span
                                                        className="icon-left"><img
                                                        src={officer} alt="Banduri"/></span>
                                                        <h4 className="text-heading-4"><span
                                                            className="text-heading-3 color-green-900">95<span
                                                            className="count">%</span></span></h4>
                                                        <p className="text-body-text color-gray-500">Growth rate
                                                            year
                                                            over year</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}
                                </div>
                                <div className="col-lg-5 d-none d-lg-block">
                                    <div className="banner-imgs">
                                        <div className="block-1 shape-2"><img src={Banner1} alt="Banduri"/>
                                        </div>
                                        <img src={Banner2} alt="Banduri"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*
            <div className="section-box mt-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-1"/>
                        <div className="col-lg-10">
                            <div className="box-image">
                                <a className="popup-youtube btn-play-video btn-play-middle" onClick={openModal}></a>
                                <img className="img-responsive bdrd-16" src={CompStock1} alt="Banduri"/></div>
                        </div>
                        <div className="col-lg-1"/>
                    </div>
                </div>
            </div>*/}
                <section className="section-box">

                    <div className="container mt-50">
                        <div className="row">
                            <div className="col-lg-4 col-sm-12">
                                <div className="card-grid-1 bg-5 hover-up">
                                    <div className="grid-1-img"><img
                                        src={Design} alt="Banduri"/></div>
                                    <h3 className="text-heading-3 color-banduri-blue-900 mt-20">Creativity</h3>
                                    <p className="text-body-excerpt mt-20">From strategy to cloud-based services and
                                        mobile
                                        apps, human-centered design is at the heart of everything we do. </p>
                                    {/* <div className="mt-30">
                                    <a href="#benefits" className="btn btn-default btn-white icon-arrow-right">Learn
                                        more</a>
                                </div>*/}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <div className="card-grid-1 bg-9 hover-up">
                                    <div className="grid-1-img"><img src={Local}
                                                                     alt="Banduri"/></div>
                                    <h3 className="text-heading-3 color-banduri-blue-900 mt-20">Flexibility</h3>
                                    <p className="text-body-excerpt mt-20">Banduri provides a flexible work environment
                                        so
                                        managing life and work doesn't have to be hard.</p>
                                    {/* <div className="mt-30">
                                    <a href="#benefits" className="btn btn-default btn-white icon-arrow-right">Learn
                                        more</a>
                                </div>*/}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <div className="card-grid-1 bg-2 hover-up">
                                    <div className="grid-1-img"><img src={Compensation}
                                                                     alt="Banduri"/></div>
                                    <h3 className="text-heading-3 color-banduri-blue-900 mt-20">Pay</h3>
                                    <p className="text-body-excerpt mt-20">Great talent deserves great compensation. We
                                        offer a competitive compensation package.</p>
                                    {/*  <div className="mt-30">
                                    <a href="#compensation"
                                       className="btn btn-default btn-white icon-arrow-right">Learn more</a>
                                </div>*/}
                                </div>
                            </div>
                            <div className="mt-20 mb-30 text-center">
                                <a href="#compensation" className="btn btn-black icon-arrow-right-white">See Our
                                    Benefits
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-sm-1 col-12"/>
                        <div className="col-lg-8 col-sm-10 col-12 text-center mt-20">
                            <h1 className="text-heading-1 color-banduri-blue-900">Join a Workplace That<br
                                className="d-lg-block d-none"/>Gives You More</h1>
                            <p className="text-body-lead-large text-center color-gray-600 mt-20">There are no big egos
                                here.
                                We are
                                collaborators, team players, and committed creators passionate about solving complex
                                problems.</p>
                            <div classname="col-lg-12 text-center"><h2
                                className="text-center text-heading-2 color-orange-900 mt-40 mb-1">
                                <span>Current Openings</span>
                            </h2>
                            </div>
                        </div>
                        <div className="col-sm-2 ">
                            <div className="img-large img-rounded mt-20">
                                <div className="img-responsive block-1 shape-1"><img src={Hiring} alt="Banduri"/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* JOB OPENINGS */
                }

                <section className="section-box mt-40">
                    <div className="container bg-2 ">
                        <div id="openings" className="col-lg-12 col-md-8 col-sm-6">

                            <div className="list-icons mt-40 hover-up">
                                <div className="item-icon"><span className="icon-left"><img
                                    src={BanduriOrgLogo} alt="Banduri"/></span>
                                    <h4 className="text-heading-4 color-banduri-blue-900">Project/Program Manager
                                        III</h4>
                                    <p className="text-body-text color-gray-600 mt-5">We are currently hiring for the
                                        position of Project/Program Manager III to provide programmatic oversight and
                                        support to an Agile enterprise-scale cloud effort that is modernizing the
                                        logistics lifecycle planning and sustainment for expeditionary forces worldwide.
                                        In this role you will develop work plans; facilitate business process
                                        standardization and
                                        improvements; monitor cost, schedule, performance metrics; support risk
                                        management; and
                                        support senior leadership with analysis and assessments of business
                                        operations.</p>
                                    <p className="btn btn-black icon-arrow-right-white  mt-20 mb-5 mr-20"><a
                                        href="https://banduri.bamboohr.com/careers/40">Apply </a></p>
                                </div>
                            </div>

                            <div id="openings" className="col-lg-12 col-md-8 col-sm-6">
                                <div className="list-icons mt-5 hover-up">
                                    <div className="item-icon"><span className="icon-left"><img
                                        src={BanduriOrgLogo} alt="Banduri"/></span>
                                        <h4 className="text-heading-4 color-banduri-blue-900">Senior Software Engineer
                                            III </h4>
                                        <p className="text-body-text color-gray-600 mt-5">We are currently hiring for
                                            the
                                            position of Senior Software Engineer III to lead the design, development,
                                            and
                                            oversight of multiple projects for an Agile, enterprise-scale cloud effort
                                            that
                                            is modernizing the
                                            logistics lifecycle planning and sustainment for expeditionary forces
                                            worldwide. </p>
                                        <p className="btn btn-black icon-arrow-right-white  mt-20 mb-5 mr-20"><a
                                            href="https://banduri.bamboohr.com/careers/42">Apply </a></p>
                                    </div>
                                </div>
                            </div>
                            <div id="openings" className="col-lg-12 col-md-8 col-sm-6">
                                <div className="list-icons mt-5 hover-up">
                                    <div className="item-icon"><span className="icon-left"><img
                                        src={BanduriOrgLogo} alt="Banduri"/></span>
                                        <h4 className="text-heading-4 color-banduri-blue-900">Software Engineer II</h4>
                                        <p className="text-body-text color-gray-600  mt-5">We are currently hiring for
                                            the
                                            position of Software Engineer II to provide requirements analysis, design,
                                            and
                                            development of cloud-based solutions for an Agile, enterprise-scale cloud
                                            effort
                                            that is modernizing the logistics lifecycle planning and sustainment for
                                            expeditionary forces
                                            worldwide.</p>
                                        <p className="btn btn-black icon-arrow-right-white mt-20 mb-5 mr-20"><a
                                            href="https://banduri.bamboohr.com/careers/41">Apply </a></p>
                                    </div>
                                </div>
                            </div>

                            <div id="openings" className="col-lg-12 col-md-8 col-sm-6">
                                <div className="list-icons mt-5 hover-up">
                                    <div className="item-icon"><span className="icon-left"><img
                                        src={BanduriOrgLogo} alt="Banduri"/></span>
                                        <h4 className="text-heading-4 color-banduri-blue-900">System Engineer III</h4>
                                        <p className="text-body-text color-gray-600 mt-5">We are currently hiring for
                                            the
                                            position of System Engineer III to assist with the design, integration, and
                                            management of large-scale architectures, across multiple disciplines and
                                            their smaller systems (or subsystems of larger systems) for an Agile,
                                            enterprise-scale cloud effort that is modernizing the logistics lifecycle
                                            planning and sustainment for expeditionary forces worldwide.</p>
                                        <p className="btn btn-black icon-arrow-right-white mt-20 mb-5 mr-20"><a
                                            href="https://banduri.bamboohr.com/careers/44">Apply </a></p>
                                        <p><br/>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div id="openings" className="col-lg-12 col-md-8 col-sm-6">
                                <div className="list-icons mt-5 hover-up">
                                    <div className="item-icon"><span className="icon-left"><img
                                        src={BanduriOrgLogo} alt="Banduri"/></span>
                                        <h4 className="text-heading-4 color-banduri-blue-900">Senior System Engineer
                                            III</h4>
                                        <p className="text-body-text color-gray-600 mt-10">We are seeking a Senior
                                            Systems Engineer III in the Quantico, VA area to provide technical expertise
                                            to Intelligence, Surveillance, and Reconnaissance (ISR), Electronic Warfare
                                            (EW), Information Warfare (IW), and National to Tactical integration (NTI)
                                            projects. With minimal oversight, this position assesses, plans,
                                            coordinates, schedules, and implements moderately sized projects from
                                            inception through system transition.</p>
                                        <p className="btn btn-black icon-arrow-right-white  mt-20 mb-5 mr-20"><a
                                            href="https://banduri.bamboohr.com/careers/45">Apply </a></p>
                                        <p><br/>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div id="openings" className="col-lg-12 col-md-8 col-sm-6">
                                <div className="list-icons mt-5 hover-up">
                                    <div className="item-icon"><span className="icon-left"><img
                                        src={BanduriOrgLogo} alt="Banduri"/></span>
                                        <h4 className="text-heading-4 color-banduri-blue-900">Tier III IT Engineer</h4>
                                        <p className="text-body-text color-gray-600 mb-5">
                                            We are seeking an experienced Tier III IT Engineer to join a large scale
                                            digital transformation effort in support of a Federal agency. In this role
                                            you will manage and support issue resolution, cybersercurity provisioning,
                                            and customer support for Azure, O365, and other Windows applications,
                                            services, and workstations using tools such as Microsoft Endpoint Manager
                                            and Intune, PowerShell, and other app packaging tools and capabilities. The
                                            customer you will be serving is a Federal agency located in downtown
                                            Washington D.C.
                                            This role interacts directly with users and requires excellent customer
                                            service skills and service. <strong>MUST BE A US CITIZEN</strong>.</p>
                                        <p className="btn btn-black icon-arrow-right-white mt-20 mb-5 mr-20"><a
                                            href="https://banduri.bamboohr.com/careers/43">Apply </a></p>
                                        <p><br/>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*     <div className="col-lg-4 col-md-12 col-sm-12">*/}
                        {/*    <div className="list-icons mt-40 hover-up">*/}
                        {/*        <div className="item-icon"><span className="icon-left"><img*/}
                        {/*            src={BanduriOrgLogo} alt="Banduri"/></span>*/}
                        {/*            <h4 className="text-heading-4">Acquisition Management Support I</h4>*/}
                        {/*            <p className="text-body-text color-gray-600 mt-15">We are currently hiring for the*/}
                        {/*                position*/}
                        {/*                of Acquisition Management Support I to support planning, scheduling, and*/}
                        {/*                management*/}
                        {/*                functions for Marine Corps Integrated Product Teams (IPTs).</p>*/}

                        {/*            <p className="btn btn-black icon-arrow-right-white mb-15 mr-20"><a*/}
                        {/*                href="https://banduri.bamboohr.com/careers/33">Apply </a></p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*    </div>*/}
                        {/*</section>*/}
                        {/*<div className=" container line-bd-green mb-20 mt-10"/>*/}
                        {/*<section className="section-box">*/}
                        {/*    <div id="compensation" className="container bg-2">*/}
                        {/*        <div className="row">*/}
                        {/*            <div className="col-lg-6 col-sm-12 mb-20 mt-40  "><span*/}
                        {/*                className="tag-1 bg-6 color-green-900">We've Got You Covered</span>*/}
                        {/*                <h3 className="text-heading-1 color-banduri-blue-900 mt-30">From the big picture to*/}
                        {/*                    every tiny detail,*/}
                        {/*                    we've*/}
                        {/*                    got you covered.</h3>*/}
                        {/*                <p className="text-body-lead-large color-gray-600 mt-30">Banduri offers a*/}
                        {/*                    variety of*/}
                        {/*                    benefits to reduce stress, give you back your time, and help you meet your*/}
                        {/*                    personal*/}
                        {/*                    and professional goals.</p>*/}
                        {/*                <div className="line-bd-green mt-50"/>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-lg-6 col-sm-12 block-we-do">*/}
                        {/*                <div className="inner-image"><img className="bdrd-16 img-responsive" src={Built}*/}
                        {/*                                                  alt="Banduri"/>*/}
                        {/*                    <div className="block-image-bottom"><img className="bdrd-16 img-responsive"*/}
                        {/*                                                             src={Built2} alt="Banduri"/></div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</section>*/}
                        {/*<div className=" container line-bd-gray mb-20 mt-10"/>*/}
                        {/*<section className="section-box ">*/}
                        {/*    <div id="perks" className="container bg-6-opacity-30 ">*/}
                        {/*        <div className="col-lg-8 col-sm-6 col-12 mt-10">*/}
                        {/*            <h4 className="text-heading-6 color-banduri-blue-900 icon-leaf">Flexible Work*/}
                        {/*                Environment</h4>*/}
                        {/*            <p className="text-body-excerpt color-gray-600 mt-15">We offer remote*/}
                        {/*                and hybrid*/}
                        {/*                options depending on customer requirements.</p>*/}
                        {/*        </div>*/}
                        {/*        <div className="col-lg-8 col-sm-6 col-12 mt-10">*/}
                        {/*            <h4 className="text-heading-6 color-banduri-blue-900 icon-leaf">Health Insurance</h4>*/}
                        {/*            <p className="text-body-excerpt color-gray-600 mt-15">We offer medical,*/}
                        {/*                dental,*/}
                        {/*                and vision, matching up to 50% of employee premiums.</p>*/}
                        {/*        </div>*/}
                        {/*        <div className="col-lg-8 col-sm-6 col-12 mt-10">*/}
                        {/*            <h4 className="text-heading-6 color-banduri-blue-900 icon-leaf">Health Savings Account*/}
                        {/*                (HSA)</h4>*/}
                        {/*            <p className="text-body-excerpt color-gray-600 mt-15">We provide an HSA*/}
                        {/*                option for*/}
                        {/*                medical benefits with an employer contribution to help you cover out of*/}
                        {/*                pocket*/}
                        {/*                costs.</p>*/}
                        {/*        </div>*/}
                        {/*        <div className="col-lg-8 col-sm-6 col-12 mt-10">*/}
                        {/*            <h4 className="text-heading-6 color-banduri-blue-900 icon-leaf">401(k) Plan</h4>*/}
                        {/*            <p className="text-body-excerpt color-gray-600 mt-15">We offer a 401(k) and*/}
                        {/*                match up*/}
                        {/*                to 4% of your earnings.</p>*/}
                        {/*        </div>*/}
                        {/*        <div className="col-lg-8 col-sm-6 col-12 mt-10">*/}
                        {/*            <h4 className="text-heading-6 color-banduri-blue-900 icon-leaf">Tuition Assistance</h4>*/}
                        {/*            <p className="text-body-excerpt color-gray-600 mt-15">We offer tuition assistance*/}
                        {/*                and paid training to keep your skills competitive in the market.</p>*/}
                        {/*        </div>*/}
                        {/*        <div className="col-lg-6 col-sm-6 col-12 mt-10">*/}
                        {/*            <h4 className="text-heading-6 color-banduri-blue-900 icon-leaf">Paid Time Off</h4>*/}
                        {/*            <p className="text-body-excerpt color-gray-600 mt-15">We offer paid time off*/}
                        {/*                with*/}
                        {/*                options for rollover and increases in accural.</p>*/}
                        {/*        </div>*/}
                    </div>
                </section>
                {/*<div className=" container line-bd-gray mb-5 mt-10"/>*/}
            </div>
            {/* <section className="section-box mt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 col-12">
                            <div className="text-start mb-25"><span className="tag-1 bg-6 color-green-900">What We Do</span></div>
                            <h2 className="text-heading-2 color-gray-900 mb-20">High performing teams need<br className="d-lg-block d-none" />a dynamic work environment.</h2>
                            <p className="text-body-lead-large color-gray-500 mt-15 pr-40">We've built an ecosystem that prioritizes interesting work, creativity, and employee well-being.</p>
                        </div>
                    </div>
                </div>
                <div className="container mt-20">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="list-icons">
                                <div className="item-icon none-bd"><span className="icon-left"><img src={Suppt} alt="Banduri" /></span>
                                    <h4 className="text-heading-6">Flexibility</h4>
                                    <p className="text-body-text color-gray-600 mt-15">We commit to original work of the highest standard and giving credit where its due.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="list-icons">
                                <div className="item-icon none-bd"><span className="icon-left"><img src={Web} alt="Agon" /></span>
                                    <h4 className="text-heading-6">Opporunity</h4>
                                    <p className="text-body-text color-gray-600 mt-15">We become a bonafide agency with a tiny team of 3 and then hire our first developers</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="list-icons">
                                <div className="item-icon none-bd"><span className="icon-left"><img src={Growth}alt="Banduri" /></span>
                                    <h4 className="text-heading-6">Collaboration</h4>
                                    <p className="text-body-text color-gray-500 mt-15">We create our first campaign for Kaleidoscope Tech and it goes viral</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="list-icons">
                                <div className="item-icon none-bd"><span className="icon-left"><img src= {Collab} alt="Banduri" /></span>
                                    <h4 className="text-heading-6">Development</h4>
                                    <p className="text-body-text color-gray-500 mt-15">With a growing body of work, we bring more artists, designers on board.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>*/
            }
            {
                modal ? (
                    <section className="modal__bg" onClick={openModal}>
                        <div className="modal__align">
                            <div className="modal__content" modal={modal}>
                                <div className="modal__video-align">
                                    {videoLoading ? (
                                        <div className="modal__spinner">
                                            <i className="fi-rr-refresh"></i>
                                        </div>
                                    ) : null}
                                    <iframe
                                        className="modal__video-style"
                                        onLoad={spinner}
                                        loading="lazy"
                                        width="800"
                                        height="500"
                                        src="https://www.youtube.com/embed/oRI37cOPBQQ"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                ) : null
            }
        </div>
    )
}

export default JobListing;
