import React from 'react';
import CAG from '../img/CAG_blk_profile.png';
import JS from '../img/JS_profile_blk3.png';
import AbtBan1 from '../img/About/banner1.png';
import AbtBan2 from '../img/About/banner2.png';
import Values from '../img/About/icon-advance.svg';
import Retent from '../img/About/icon-active.svg';
import Mission from '../img/About/icon-work.svg';
import VidImg from '../img/About/img-1.png';


function About() {
    return (

        <section className="section-box">
            <div className="banner-hero banner-2 bg-about-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7"><span
                            className="tag-1 color-orange-900">What We Do, What You Get</span>
                            <h1 className="text-display-3 color-banduri-blue-900 mt-20">We turn change into<span
                                className="color-orange-900"> value</span></h1>
                            <p className="text-body-lead-large color-gray-500 mt-40 pr-40">Our teams bring decades
                                of
                                experience driving
                                large-scale innovation and modernization initiatives
                                to your most complex problems. </p>
                            <div className="mt-40"><a className="btn btn-black shape-square icon-arrow-right-white"
                                                      href="/Jobs">Join Our Team</a>
                                <a className="btn btn-link color-gray-900 icon-arrow-right text-heading-6"
                                   href="/Contact">Contact Us</a></div>
                        </div>
                        <div className="col-lg-5 d-none d-lg-block">
                            <div className="banner-img">
                                <div className="block-1 shape-1"><img src={AbtBan2} alt="Banduri"/>
                                </div>
                                <img className="img-responsive shape-2" alt="Banduri" src={AbtBan1}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className="section-box mt-40">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-1 col-sm-1 col-12"></div>
                        <div className="col-lg-10 col-sm-10 col-12 text-center">
                            <h2 className="text-display-3 color-banduri-blue-900 mb-60">We believe in the power<br
                                className="d-lg-block d-none"/> of creative ideas</h2>
                        </div>
                        <div className="col-lg-1 col-sm-1 col-12"></div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-10">
                            <div className="box-image"><a className="popup-youtube btn-play-video btn-play-middle"
                                                          href="https://youtu.be/AfcRLmxGIZs"></a><img
                                className="img-responsive bdrd-16" src={VidImg} alt="Banduri"/>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                    </div>
                </div>
            </section>
            <section className="section-box mt-20">
                <div className="container mt-20">
                    <div className="text-center mb-20"><span
                        className="tag-1 bg-6 color-green-900">Who We Are, What We Value</span></div>
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="list-icons mt-20">
                                <div className="item-icon"><span className="icon-left"><img
                                    src={Values} alt="Banduri"/></span>
                                    <h4 className="text-heading-4 color-banduri-blue-900">1. Values</h4>
                                    <p className="text-body-text color-gray-600 mt-15">We value getting it right
                                        over
                                        being right, diversity over protocol, the power of what if, and intentional
                                        design.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="list-icons mt-20">
                                <div className="item-icon"><span className="icon-left"><img
                                    src={Mission} alt="banduri"/></span>
                                    <h4 className="text-heading-4 color-banduri-blue-900">2. Mission</h4>
                                    <p className="text-body-text color-gray-600 mt-15"> Our mission is to leave
                                        things
                                        better than we found them, make things that
                                        matter, and help our customers harness the power of change.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="list-icons mt-20">
                                <div className="item-icon"><span className="icon-left"><img
                                    src={Retent} alt="Banduri"/></span>
                                    <h4 className="text-heading-4 color-banduri-blue-900">3. Team</h4>
                                    <p className="text-body-text color-gray-600 mt-15 "> We are
                                        collaborators, team players, solutions-oriented problem solvers, and
                                        committed
                                        creators that are passionate about what we do. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-box mt-40">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-sm-1 col-12"></div>
                        <div className="col-lg-8 col-sm-10 col-12 text-center">
                            <h3 className="text-center text-heading-3 color-banduri-blue-900 mb-10"><span>Meet the Leadership Team</span>
                            </h3>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="card-grid-style-6 hover-up">
                                        <div className="grid-6-img mb-15"><img
                                            src={CAG}
                                            alt="Banduri"/></div>
                                        <span className="text-body-text-md color-gray-500">CEO</span>
                                        <h3 className="text-heading-5 color-banduri-blue-900 mb-5 mt-5">Christine
                                            Goodwin</h3>
                                        <p className="text-body-excerpt text-desc color-gray-500 mt-15 mb-20">Christine
                                            was named one of Virginia's top 50 Entrepreneurs and has over 25 years of
                                            experience designing and developing enterprise-scale software solutions
                                            using leading edge
                                            technology in industry, government, and national and military intelligence.
                                            She has worked with over 2,000 startup founders, innovators, executives, and
                                            active duty military personnel since 2013 to
                                            help them solve thorny problems, develop lean business models, validate
                                            product-market fit, and deliver complex solutions fast.</p>
                                        <span
                                            className="text-body-text-md color-gray-600">Let&rsquo;s Connect</span>
                                        <div className="social-bottom">
                                            <a
                                                className="icon-socials icon-linkedin"
                                                href="https://www.linkedin.com/in/cgoody2shoes/"></a></div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="card-grid-style-6 hover-up">
                                        <div className="grid-6-img mb-15"><img
                                            src={JS}
                                            alt="Banduri"/></div>
                                        <span className="text-body-text-md color-gray-500">COO</span>
                                        <h3 className="text-heading-5 color-banduri-blue-900 mb-5 mt-5">Julianne
                                            Skinner</h3>
                                        <p className="text-body-excerpt text-desc color-gray-500 mt-15 mb-20">Julianne
                                            has over 15 years of experience as an Enterprise Architect designing and
                                            delivering large-scale solutions for federal agencies, and national and
                                            military intelligence customers. She has designed and facilitated over 500
                                            Innovation workshops; developed Lean Portfolio Management strategies for
                                            industry and government customers; worked with exexcutives to design
                                            holistic organizational designs and strategies; and mentored hundreds of
                                            innovators since
                                            2014.</p>
                                        <span
                                            className="text-body-text-md color-gray-600">Let&rsquo;s Connect</span>
                                        <div className="social-bottom"><a
                                            className="icon-socials icon-linkedin"
                                            href="https://www.linkedin.com/in/julianne-skinner-cissp-cea-pmp-40462619/"></a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-1 col-12"></div>
                    </div>
                </div>
            </section>


        </section>

    );
}

export default About;
