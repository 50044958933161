import React from 'react';

import P1 from '../img/page/blog/2/noun-innovation-3925825-F35D23.png';
import P2 from '../img/page/blog/2/noun-join-2916887-F35D23.png';
import P3 from '../img/page/blog/2/noun-learning-2484518-F35D23.png';
import P4 from '../img/page/blog/2/noun-program-2796040-F35D23.png';

function Accelerate() {
    return (

        <section className="section-box" id="innovation">

            <div className="row mt-10 mb-50">

                <div className="bdrd-58 box-gray-100">
                    <div className="row">
                        <div className="col-lg-12"/>
                        <div className="col-lg-12 col-sm-10 col-12 text-center">
                            <h2 className="text-heading-1 color-banduri-blue-900 mt-10">Facilitation & Innovation
                                Acceleration</h2>
                            <p className="text-body-lead-large color-gray-600 mt-10">We offer workshops and time-limited
                                accelerator programs for organizations looking to quickly establish<br
                                    className="d-lg-block d-none"/>continuous innovation.</p>
                        </div>
                        <div className="col-lg-2 col-sm-1 col-12"/>
                    </div>
                    <div className="container mt-10">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="card-grid-style-2 bg-2 hover-up mb-10">
                                    <h5 className="text-heading-5 color-banduri-blue-900">Accelerate Innovation</h5>
                                    <p className="text-body-text color-gray-600 mt-20 text-comment">Organizations need
                                        to evolve faster than pacing threats. We create integrated cultural,
                                        technological, and operational solutions that provide adaptive responses to
                                        change.</p>
                                    <div className="box-img-user">
                                        <div className="img-user"><img src={P1}
                                                                       alt="Banduri"/></div>
                                        <h4 className="text-body-lead color-banduri-blue-900 mb-5">Outpace Threats</h4>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="card-grid-style-2 bg-2 hover-up mb-10">
                                    <h5 className="text-heading-5 color-banduri-blue-900">Join 1,500+ People</h5>
                                    <p className="text-body-text color-gray-600 mt-20 text-comment">Join a community of
                                        like-minded innovators. Our workshops run from 1-day to 12-weeks and help you
                                        scope
                                        the right problems, and quickly deliver measurable value.</p>
                                    <div className="box-img-user">
                                        <div className="img-user"><img src={P2}
                                                                       alt="Banduri"/></div>
                                        <h4 className="text-body-lead color-banduri-blue-900 mb-5">Build Community</h4>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="card-grid-style-2 bg-2 hover-up mb-10">
                                    <h5 className="text-heading-5 color-banduri-blue-900">Trained Facilitators</h5>
                                    <p className="text-body-text color-gray-600 mt-20 text-comment">Our facilitators
                                        apply decades of experience in Agile, Engineering, Government, and Industry to
                                        help you create innovative solutions to complex problems fast.</p>
                                    <div className="box-img-user">
                                        <div className="img-user"><img src={P3} alt="Banduri"/></div>
                                        <h4 className="text-body-lead color-banduri-blue-900 mb-5">Collaborate</h4>
                                    </div>


                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="card-grid-style-2 bg-2 hover-up mb-10">
                                    <h5 className="text-heading-5 color-banduri-blue-900">Focus on Execution</h5>
                                    <p className="text-body-text color-gray-600 mt-20 text-comment">We develop execution
                                        frameworks that align the people, processes, and technology in your organization
                                        so you can focus on results. </p>
                                    <div className="box-img-user">
                                        <div className="img-user"><img src={P4}
                                                                       alt="Banduri"/></div>
                                        <h4 className="text-body-lead color-banduri-blue-900 mb-5">Deliver Value</h4>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    )
}

export default Accelerate;
