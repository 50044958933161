import React from 'react';

function Hook() {
    return (
        <div id="hook">

            <section className="section-box" id="intro">
                <div className="banner-hero banner-3">
                    <div className="container">
                        <div className="text-center"><span
                            className="tag-1 bg-6 color-green-900">Hello!</span>
                            <p><h1 className="text-display-2 color-banduri-blue-900 mt-30">Complex Digital
                                Transformations <br/> Are Our <em><strong>Specialty</strong></em></h1></p>
                            <div className="text-body-lead-large color-gray-500 mt-40">Our teams apply proven experience
                                in Agile, Business Operations, ITSM, Cloud,<br/> Cybersecurity Engineering, and
                                Automation to help large organizations <br
                                    className="d-lg-block d-none"/>implement and scale
                                complex modernization initiatives.
                            </div>

                            {/* <div className="mt-50">
                                <a href="#openings" className="btn btn-black icon-arrow-right-white mb-15 mr-20">Current
                                    Openings</a>
                            </div>*/}
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default Hook;
