import React, {useEffect} from 'react';
import IntHeader from "../elements/IntHeader";
import Footer from "../elements/Footer";
import Contact from "../elements/Contact";
import ReactGA from 'react-ga';
import DesignPortfolio from "../elements/DesignPortfolio.js";
import Accelerate from "../elements/Accelerate";

function Design() {
    useEffect(() => {
        ReactGA.initialize('UA-148043567-1');
        ReactGA.pageview('/design');
        ReactGA.event({
            category: 'Visitor',
            action: 'view design info'
        });

        let qs, js, q, s, d = document,
            gi = d.getElementById,
            ce = d.createElement,
            gt = d.getElementsByTagName,
            id = "typeform",
            b = "https://embed.typeform.com/";

        if (!gi.call(d, id)) {
            js = ce.call(d, "script");
            js.id = id;
            js.src = b + "embed.js";
            q = gt.call(d, "script")[0];
            q.parentNode.insertBefore(js, q);
        }
    }, []);

    return (
        <div id="Design">
            <IntHeader/>
            <section className="section-box">
                <div className="banner-hero banner-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <h1 className="text-display-2 color-banduri-blue-900">
                                    Design is a force
                                    <span className="color-orange-900"> multiplier</span>
                                </h1>
                                <p className="text-body-lead-large color-gray-500 mt-10 pr-40">
                                    Good design grabs your attention. Great design provokes you.
                                    Exceptional design changes how you think, you work, and you live.
                                </p>
                                <div className="mt-20">
                                    <a href="https://form.typeform.com/to/RocRIT"
                                       className="btn btn-black icon-arrow-right-white mb-20">Get More Info
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-box mt-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-sm-1 col-12"/>
                        <div className="col-lg-8 col-sm-10 col-12 text-center mt-40">
                            <h2 className="text-heading-1 color-banduri-blue-900 mb-10">Create real value.</h2>
                            <p className="text-body-lead-large color-gray-600 mt-20">Designing for change is a force
                                multiplier for
                                evolving, creating, building, and delivering value.</p>
                        </div>

                    </div>
                </div>
            </section>
            <DesignPortfolio/>
            <div className="col-lg-12">

            </div>
            <Footer/>
        </div>
    );
}

export default Design;