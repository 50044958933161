import React from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import App from './App';
import Home from './pages/Home';
import Resources from './pages/Resources';
import Team from './pages/Team';
import Jobs from './pages/Jobs';
import Thinking from './pages/Blog';
import Events from './pages/Events';
import ContactUs from './pages/ContactUs';
import Terms from './pages/Terms';
import NotFound from './NotFound';
import Agile from './pages/Agile';
import Products from './pages/Products';
import ErrorHandler from './ErrorHandler';
import history from './history';
import Design from "./pages/Design";
import Development from "./pages/Development";
import Contracts from "./pages/Contracts";

function MakeMainRoutes() {
    return (
        <Router history={history} component={App}>
            <div>
                <ErrorHandler>
                    <Switch>
                        <Route exact path="/" render={(props) => <Home {...props} />}/>
                        <Route exact path="/resources" render={(props) => <Resources {...props} />}/>
                        <Route exact path="/team" render={(props) => <Team {...props} />}/>
                        <Route exact path="/jobs" render={(props) => <Jobs {...props} />}/>
                        <Route exact path="/thinking" render={(props) => <Thinking {...props} />}/>
                        <Route exact path="/events" render={(props) => <Events {...props} />}/>
                        <Route exact path="/contact" render={(props) => <ContactUs {...props} />}/>
                        <Route exact path="/legal" render={(props) => <Terms {...props} />}/>
                        <Route exact path="/agile" render={(props) => <Agile {...props} />}/>
                        <Route exact path="/design" render={(props) => <Design {...props} />}/>
                        <Route exact path="/development" render={(props) => <Development {...props} />}/>
                        <Route exact path="/products" render={(props) => <Products {...props} />}/>
                        <Route exact path="/contracts" render={(props) => <Contracts {...props} />}/>
                        <Route exact path="/terms" render={(props) => <Terms {...props} />}/>
                        <Route render={() => <NotFound/>}/>
                    </Switch>
                </ErrorHandler>
            </div>
        </Router>
    );
}

export default MakeMainRoutes;