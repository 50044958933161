import React, {useEffect} from 'react';
import P1 from '../img/lance-anderson-QdAAasrZhdk-unsplash.jpg';
import P2 from '../img/nicholas-cappello-Wb63zqJ5gnE-unsplash.jpg';
import P3 from '../img/adi-goldstein-EUsVwEOsblE-unsplash.jpg';

function Portfolio() {
    useEffect(() => {
        window.initSliders();
    }, []);

    return (
        <div id="intro">
            <section className="section-box ">
                <div className="row">
                    <div id="portfolioSlider">
                        <div className="col-lg-4 wp4 slides">
                            <div className="col-lg-12 col-lg-12">
                                <div className="card-grid-1 bg-2 hover-up">
                                    <div className="overlay-effect effects clearfix">
                                        <div className="img">
                                            <img src={P2} alt="Portfolio Item"/>
                                            <div className="overlay">
                                                <a href="/Workshops" className="expand"><i
                                                    className="fa fa-search"/><br/>View
                                                    More</a>
                                                <a className="close-overlay hidden">x</a>
                                            </div>
                                            <p className="heading-sm-card mt-10"><strong>AGILE</strong>
                                            </p>
                                            <p className="info-sm-card">We build Lean Portfolios that organize and
                                                manage operational
                                                activities so they continuously deliver <em><strong>measurable
                                                    value</strong></em>.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wp4 slides">
                            <div className="col-lg-12 col-lg-12">
                                <div className="card-grid-1 bg-5 hover-up">
                                    <div className="overlay-effect effects clearfix">
                                        <div className="img">
                                            <img src={P1} alt="Portfolio Item"/>
                                            <div className="overlay">
                                                <a href="/design" className="expand"><i className="fa fa-search"/><br/>View
                                                    More</a>
                                                <a className="close-overlay hidden">x</a>
                                            </div>
                                            <p className="heading-sm-card mt-10"><strong>DESIGN</strong></p>
                                            <p className="info-sm-card">We work with teams to build executable
                                                designs with outcomes whose
                                                value can be measured by <em><strong>results that
                                                    count</strong></em>.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 wp4 slides">
                            <div className="col-lg-12 col-lg-12">
                                <div className="card-grid-1 bg-1 hover-up">
                                    <div className="overlay-effect effects clearfix">
                                        <div className="img">
                                            <img src={P3} alt="Portfolio Item"/>
                                            <div className="overlay">
                                                <a href="/Development" className="expand"><i
                                                    className="fa fa-search"/><br/>View
                                                    More</a>
                                                <a className="close-overlay hidden">x</a>
                                            </div>
                                            <p className="heading-sm-card mt-10"><strong>DEVELOPMENT</strong>
                                            </p>
                                            <p className="info-sm-card">We build products and services that <em><strong>people
                                                want to
                                                use</strong></em> because
                                                they solve problems that matter.</p>
                                            <p><br/></p>
                                       

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*  <div className="col-md-4 wp4 delay-05s">
                                    <div className="overlay-effect effects clearfix">
                                        <div className="img">
                                            <img src={P2} alt="Portfolio Item"/>
                                            <div className="overlay">
                                                <a href="/thinking" className="expand"><i className="fa fa-search"/><br/>View
                                                    More</a>
                                                <a className="close-overlay hidden">x</a>
                                            </div>
                                        </div>
                                    </div>
                                    <h2>Innovation</h2>
                                    <p>Successful innovation meets emerging needs that are designed to and built by
                                        cross-functional teams operating to deliver shared value and using resources
                                        efficiently. We work with organizations to create an <em>execution framework for
                                            rapidly creating and scaling </em> innovation. </p>
                                </div>
                                <div className="col-md-4 wp4 delay-1s">
                                    <div className="overlay-effect effects clearfix">
                                        <div className="img">
                                            <img src={P3} alt="Portfolio Item"/>
                                            <div className="overlay">
                                                <a href="/thinking" className="expand"><i className="fa fa-search"/><br/>View
                                                    More</a>
                                                <a className="close-overlay hidden">x</a>
                                            </div>
                                        </div>
                                    </div>
                                    <h2>Organizational Design</h2>
                                    <p>Most organizations react to change. Staying ahead of pacing threats requires an
                                        organizational design that is
                                        <em> adaptive and scalable</em>. We work with you to define and deliver <em>modular
                                            organizational
                                            designs</em> that allow you to task organize lines of effort to drive
                                        change.</p>
                                </div>
                            </li>*/}

                            {/*  <div className="col-md-4 wp4 delay-05s">
                                    <div className="overlay-effect effects clearfix">
                                        <div className="img">
                                            <img src={P2} alt="Portfolio Item"/>
                                            <div className="overlay">
                                                <a href="/thinking" className="expand"><i className="fa fa-search"/><br/>View
                                                    More</a>
                                                <a className="close-overlay hidden">x</a>
                                            </div>
                                        </div>
                                    </div>
                                    <h2>Technology Strategy and Design</h2>
                                    <p>We use a human-centered approach to technology design and development
                                        so that <em>solutions integrate intuitively </em> into an organization's
                                        environment and
                                        have validated problem-solution fit before being deployed across the
                                        enterprise.</p>
                                </div>*/}


                        </div>
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3 text-center">
                                {/*  <a href="https://banduri.typeform.com/to/MXexfK" className="learn-more-btn">Request Info</a>*/}
                                <div className="row">
                                    <div className="row text-center">
                                        <a data-scroll href="#features">
                                            <div className="navarrow"></div>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Portfolio;
