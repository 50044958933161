import React, {useState} from "react";

function Accordion() {
    const [isActive, setIsActive] = useState({
        status: false,
        key: 1,
    });

    const handleToggle = (key) => {
        if (isActive.key === key) {
            setIsActive({
                status: false,
            });
        } else {
            setIsActive({
                status: true,
                key,
            });
        }
    };
    return (


            <div className="accordion" id="accordionFAQ">
                <div className="accordion-item">
                    <h2 className="accordion-header" onClick={() => handleToggle(1)}>
                        <button
                            className={isActive.key == 1 ? "accordion-button text-heading-5 " : "accordion-button text-heading-5 collapsed"}>How
                            do I procure your services from GSA?
                        </button>
                    </h2>
                    <div
                        className={isActive.key == 1 ? "accordion-collapse collapse show" : "accordion-collapse collapse "}>
                        <div className="accordion-body">
                            <p className="text-body-text color-gray-600 mt-5">Customers can review our services and
                                rates <a className="color-orange-900"
                                         href="https://www.gsaelibrary.gsa.gov/ElibMain/contractorInfo.do?contractNumber=47QTCA22D00D5&contractorName=BANDURI+LLC&executeQuery=YES"> here </a>on
                                GSA. To order our services online, please use <a
                                    className="color-orange-900" href="https://www.ebuy.gsa.gov/ebuy/">GSA
                                    eBuy.</a></p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" onClick={() => handleToggle(2)}>
                        <button
                            className={isActive.key == 2 ? "accordion-button text-heading-5 " : "accordion-button text-heading-5 collapsed"}>I'm
                            a large prime contractor. How
                            can I team with you on an effort I am pursuing?
                        </button>
                    </h2>
                    <div
                        className={isActive.key == 2 ? "accordion-collapse collapse show" : "accordion-collapse collapse "}>
                        <div className="accordion-body">
                            <p className="text-body-text color-gray-600 mt-10">We look forward to speaking with
                                you! To reach our contracting officers,
                                use the information below:</p>

                            <p className="text-body-text-md">
                                <ul>
                                    <p className="text-body-text text-info">
                                        <i className="ionicons ion-android-chat  color-orange-900"/> <em><strong>Contracts
                                        POC:</strong></em> Julianne Skinner or Christine Goodwin
                                    </p>
                                    <p className="text-body-text text-info">
                                        <i className="ionicons ion-android-call  color-orange-900"/>
                                        <em><strong> Phone:</strong></em> 540-699-0805
                                    </p>
                                    <p className="text-body-text text-info">
                                        <i className="ionicons ion-ios7-location  color-orange-900"/>
                                        <em><strong> Address:</strong></em> 400
                                        Charles Street
                                        Fredericksburg VA 22401
                                    </p>
                                    <p className="text-body-text text-info">
                                        <i className="ionicons ion-android-mail color-orange-900"/> <a
                                        href="mailto:contracts@banduri.com">
                                        <em><strong> Email:</strong></em> contracts@banduri.com</a>
                                    </p>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" onClick={() => handleToggle(3)}>
                        <button
                            className={isActive.key == 3 ? "accordion-button text-heading-5 " : "accordion-button text-heading-5 collapsed"}>What
                            are the advantages of using GSA?
                        </button>
                    </h2>
                    <div
                        className={isActive.key == 3 ? "accordion-collapse collapse show" : "accordion-collapse collapse "}>
                        <div className="accordion-body">
                            <p className="text-body-text color-gray-600 mt-5">
                                Banduri offers a range of IT, Cloud, and Cybersecurity Engineering services
                                as
                                well as integrated training and facilitation services for Agile, Lean
                                Portfolio Management, and Innovation Strategy as a prime contractor on the
                                GSA Multiple Award Schedule (MAS) schedule.
                                Advantages of the GSA MAS include:</p>

                            <p>
                                <ul>
                                    <li className="text-body-text text-info"><i
                                        className="ionicons ion-checkmark-round color-orange-900"/><strong> Savings</strong> –
                                        Competitive
                                        market-based pricing that
                                        leverages the
                                        buying power of the federal government, with the ability to
                                        negotiate further
                                        discounts at the order level.
                                    </li>
                                    <li className="text-body-text  text-info"><i
                                        className="ionicons ion-checkmark-round color-orange-900"/>
                                        <strong> Flexibility </strong> – The ability to tailor
                                        orders to get
                                        what you need by customizing terms and conditions at the order
                                        level.
                                    </li>

                                    <li className="text-body-text  text-info"><i
                                        className="ionicons ion-checkmark-round color-orange-900"/>
                                        <strong> Efficiency</strong> due to:
                                        <ul>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900 "/> Shorter
                                                lead time
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Transparency
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Control
                                                of the procurement
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Lower
                                                administrative costs
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" onClick={() => handleToggle(4)}>
                        <button
                            className={isActive.key == 4 ? "accordion-button text-heading-5 " : "accordion-button text-heading-5 collapsed"}>What
                            SBA Certifications does Banduri have?
                        </button>
                    </h2>
                    <div
                        className={isActive.key == 4 ? "accordion-collapse collapse show" : "accordion-collapse collapse "}>
                        <div className="accordion-body">
                            <p className="text-body-text color-gray-600 mt-5">Working with Banduri gives Government
                                agencies and large businesses an opportunity to meet the following SBA-certified
                                small-business set aside designations:</p>
                            <p className="text-body-text-md">
                                <ul>
                                    <li className="text-body-text text-info col-xs-offset-1">
                                        <i className="ionicons ion-checkmark-round color-orange-900"/> Women-Owned (WO)
                                    </li>
                                    <li className="text-body-text text-info col-xs-offset-1">
                                        <i className="ionicons ion-checkmark-round color-orange-900"/> Women-Owned Small
                                        Business (WOSB)
                                    </li>
                                    <li className="text-body-text text-info col-xs-offset-1">
                                        <i className="ionicons ion-checkmark-round color-orange-900"/> Economically
                                        Disadvantaged Women-Owned Small Business (EDWOSB)
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" onClick={() => handleToggle(5)}>
                        <button
                            className={isActive.key == 5 ? "accordion-button text-heading-5 " : "accordion-button text-heading-5 collapsed"}>What
                            past performance does Banduri have?
                        </button>
                    </h2>
                    <div
                        className={isActive.key == 5 ? "accordion-collapse collapse show" : "accordion-collapse collapse "}>
                        <div className="accordion-body">
                            <p className="text-body-text color-gray-600 mt-5">Banduri has proven past performance in
                                the following core competencies:
                            </p>
                            <p>
                                <ul>
                                    <li className="text-body-text  text-info"><i
                                        className="ionicons ion-checkmark-round color-orange-900"/>
                                        <strong> Enterprise IT Modernization and Digital Transformation</strong>:
                                        <ul>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900 "/> IT Strategy
                                                (ITSM)
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Capital Planning
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Organizational
                                                Design
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Business Process
                                                Engineering
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="text-body-text  text-info"><i
                                        className="ionicons ion-checkmark-round color-orange-900"/>
                                        <strong> Cybersecurity Engineering</strong>:
                                        <ul>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900 "/> Zero Trust
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> DevSecOps
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Cybersecurity
                                                Planning & Strategy
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> NIST & CISA
                                                Frameworks
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="text-body-text  text-info"><i
                                        className="ionicons ion-checkmark-round color-orange-900"/>
                                        <strong> Cloud Engineering & Design</strong>:
                                        <ul>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900 "/> Cloud
                                                Architecture (AWS, Azure, etc.)
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Cloud Security
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> SaaS, DaaS, IaaS,
                                                PaaS, IaC, SecaaS, FaaS
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Automation
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="text-body-text  text-info"><i
                                        className="ionicons ion-checkmark-round color-orange-900"/>
                                        <strong> Scaled Agile Framework (SAFe)</strong>:
                                        <ul>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900 "/> Lean Portfolio
                                                Management
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Kanban & Scrum
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Value Stream
                                                Development & Delivery
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Performance
                                                Management
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Jira, ServiceNow,
                                                PowerBI, etc.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </p>
                        </div>

                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" onClick={() => handleToggle(6)}>
                        <button
                            className={isActive.key == 6 ? "accordion-button text-heading-5 " : "accordion-button text-heading-5 collapsed"}>What
                            NAICS codes does Banduri support?
                        </button>
                    </h2>
                    <div
                        className={isActive.key == 6 ? "accordion-collapse collapse show" : "accordion-collapse collapse "}>
                        <div className="accordion-body">
                            <p className="text-body-text color-gray-600 mt-5">Banduri provides services under the
                                following NAICS codes:
                            </p>
                            <p className="text-body-text-md">
                                <ul>
                                    <p className="text-info">
                                        <i className="ionicons ion-checkmark-round color-orange-900"/> 541512 - Computer
                                        Systems Design Services (Primary)
                                    </p>

                                    <p className="text-info">
                                        <i className="ionicons ion-checkmark-round color-orange-900"/> 519190 - All
                                        Other Information Services
                                    </p>
                                    <p className="text-info">
                                        <i className="ionicons ion-checkmark-round color-orange-900"/> 541330 -
                                        Engineering Services
                                    </p>
                                    <p className="text-info">
                                        <i className="ionicons ion-checkmark-round color-orange-900"/> 541490 - Other
                                        Specialized Design Services

                                    </p>
                                    <p className="text-info">
                                        <i className="ionicons ion-checkmark-round color-orange-900"/> 541511 - Custom
                                        Computer Programming Services
                                    </p>
                                    <p className="text-info">
                                        <i className="ionicons ion-checkmark-round color-orange-900"/> 541519 - Other
                                        Computer Related Services
                                    </p>
                                    <p className="text-info">
                                        <i className="ionicons ion-checkmark-round color-orange-900"/> 541611 -
                                        Administrative Management and General Management Consulting Services

                                    </p>
                                    <p className="text-info">
                                        <i className="ionicons ion-checkmark-round color-orange-900"/> 541690 - Other
                                        Scientific and Technical Consulting Services
                                    </p>
                                    <p className="text-info">
                                        <i className="ionicons ion-checkmark-round color-orange-900"/> 541715 - Research
                                        and Development in the Physical, Engineering, and Life Sciences (except
                                        Nanotechnology and Biotechnology)
                                    </p>
                                    <p className="text-info">
                                        <i className="ionicons ion-checkmark-round color-orange-900"/> 561990 - All
                                        Other Support Services

                                    </p>
                                    <p className="text-info">
                                        <i className="ionicons ion-checkmark-round color-orange-900"/> 611430 -
                                        Professional and Management Development Training
                                    </p>

                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" onClick={() => handleToggle(7)}>
                        <button
                            className={isActive.key == 7 ? "accordion-button text-heading-5 " : "accordion-button text-heading-5 collapsed"}>Which
                            government agencies has Banduri supported?
                        </button>
                    </h2>
                    <div
                        className={isActive.key == 7 ? "accordion-collapse collapse show" : "accordion-collapse collapse "}>
                        <div className="accordion-body">
                            <p className="text-body-text color-gray-600 mt-5">Banduri has proven past performance in
                                the following core competencies:
                            </p>

                            <ul>
                                <li className="text-body-text  text-info"><i
                                    className="ionicons ion-checkmark-round color-orange-900"/>
                                    <strong> Headquarters Marine Corps | Deputy Commandant Information (DC I) |
                                        Information War Room | Strategic Digital Transformation</strong>:
                                    <ul>
                                        <li className="text-body-text text-info col-xs-offset-1"><i
                                            className="ionicons ion-ios7-star color-orange-900 "/> Scaled Agile
                                            Framework (SAFe) Implementation
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Lean Portfolio
                                                Management (LPM)
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Innovation
                                                Incubators
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> DC I Information
                                                Enterprise Portfolio (IEP)Strategy & Roadmap
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Marine Corps
                                                Maritime Power in the Information (MPIE) Strategy
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Executive Coaching
                                                for SAFe
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="text-body-text  text-info"><i
                                        className="ionicons ion-checkmark-round color-orange-900"/>
                                        <strong> United States Tax Court | Chief Information Officer | Office of
                                            Information Systems | US Tax Court Modernization Effort</strong>:
                                        <ul>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900 "/> Cybersecurity
                                                Auditing, Planning, & Remediation
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> DISA STIG
                                                Implementation
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Physical & Virtual
                                                Network Engineering
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> DevSecOps &
                                                Automation
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Zero Trust
                                                Implementation & Operations
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> AWS and Azure
                                                Development
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Continuous
                                                Monitoring & Defensive Cyber
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="text-body-text  text-info"><i
                                        className="ionicons ion-checkmark-round color-orange-900"/>
                                        <strong> Headquarters Marine Corps | Deputy Commandant Programs and Resources
                                            (DC P&R) | Business Mission Area (BMA) Modernization</strong>:
                                        <ul>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900 "/> Enterprise
                                                Architecture
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Cloud Architecture
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Business Process
                                                Engineering
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> DevSecOps &
                                                Automation
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Cybersecurity
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Lean Portfolio
                                                Management (LPM)
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> SAFe
                                                Implementation, Training, & Executive Coaching
                                            </li>
                                        </ul>
                                    </li>
                                <li className="text-body-text  text-info"><i
                                    className="ionicons ion-checkmark-round color-orange-900"/>
                                    <strong> Marine Corps Systems Command (MCSC)| PEO MLB | PMW230 Cloud
                                        Modernization</strong>:
                                    <ul>
                                        <li className="text-body-text text-info col-xs-offset-1"><i
                                            className="ionicons ion-ios7-star color-orange-900 "/> Enterprise
                                            Architecture
                                        </li>
                                        <li className="text-body-text text-info col-xs-offset-1"><i
                                            className="ionicons ion-ios7-star color-orange-900"/> Systems
                                            Engineering
                                        </li>
                                        <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Cloud Architecture
                                            </li>
                                            <li className="text-body-text text-info col-xs-offset-1"><i
                                                className="ionicons ion-ios7-star color-orange-900"/> Business Case
                                                Analysis
                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                        </div>
                    </div>
                </div>
            </div>

    )
}

export default Accordion;