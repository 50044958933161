import React from 'react';
import IntHeader from "../elements/IntHeader";
import Footer from "../elements/Footer";
import Contact from "../elements/Contact";
import ReactGA from "react-ga";

function ContactUs() {
    ReactGA.initialize('UA-148043567-1');
    ReactGA.pageview('/ContactUs');
    ReactGA.event({
        category: 'Visitor',
        action: 'Contact Us'
    });

    return (
        <div id="Contact">
            <IntHeader/>
            <Contact/>
            <Footer/>
        < /div>
    );
}

export default ContactUs;