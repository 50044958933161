import React from 'react';
import IntHeader from "../elements/IntHeader";
import Footer from "../elements/Footer";
import ReactGA from 'react-ga';

function Terms() {
    ReactGA.initialize('UA-148043567-1');
    ReactGA.pageview('/contact');
    ReactGA.event({
        category: 'Visitor',
        action: 'view terms'
    });
    return (
        <div id="Terms">
            <IntHeader/>
            <div className="content container">
                <h1 className="arrow text-center">Terms and Conditions for Banduri.com</h1>

                <h2 className="text-heading-3 color-gray-900 mt-20 mb-10">Introduction</h2>
                <p className="text-body-text color-gray-600 mt-20">The Website Standard Terms and Conditions written on
                    this webpage shall manage your use of our
                    website, content, domain (banduri.com) and all other information, assets, and digital capabilities
                    accessible at banduri.com.</p>
                <p className="text-body-text color-gray-600 mt-20">These Terms will be applied fully and affect your use
                    of this Website. By using this Website, you
                    agree to accept all terms and conditions written in here. You must not use this Website if you
                    disagree with any of these Website Standard Terms and Conditions.</p>

                 <h2 className="text-heading-3 color-gray-900 mt-20 mb-10">Intellectual Property Rights</h2>
                <p className="text-body-text color-gray-600 mt-20">Other than the content you own, under these Terms,
                    Banduri and/or its licensors own all the
                    intellectual property rights and materials contained in this Website.</p>
                <p className="text-body-text color-gray-600 mt-20">You are granted limited license only for purposes of
                    viewing the material contained on this
                    Website.</p>

                <h2 className="text-heading-3 color-gray-900 mt-20 mb-10">Restrictions</h2>
                <p className="text-body-text color-gray-600 mt-20">You are specifically restricted from all of the
                    following:</p>
                <ul>
                    <li className="text-body-text text-info ">
                        <i className="ionicons ion-checkmark-round color-orange-900"/> Publishing any Website material
                        in any other media;
                    </li>
                    <li className="text-body-text text-info ">
                        <i className="ionicons ion-checkmark-round color-orange-900"/> Selling, sublicensing and/or
                        otherwise commercializing any Website material;
                    </li>
                    <li className="text-body-text text-info ">
                        <i className="ionicons ion-checkmark-round color-orange-900"/> Publicly performing and/or
                        showing any Website material;
                    </li>
                    <li className="text-body-text text-info ">
                        <i className="ionicons ion-checkmark-round color-orange-900"/> Using this Website in any way
                        that is or may be damaging to this Website;
                    </li>
                    <li className="text-body-text text-info ">
                        <i className="ionicons ion-checkmark-round color-orange-900"/> Using this Website in any way
                        that impacts user access to this Website;
                    </li>
                    <li className="text-body-text text-info ">
                        <i className="ionicons ion-checkmark-round color-orange-900"/> Using this Website contrary to
                        applicable laws and regulations, or in any way may cause harm to
                        the Website, or to any person or business entity;
                    </li>
                    <li className="text-body-text text-info ">
                        <i className="ionicons ion-checkmark-round color-orange-900"/> Engaging in any data mining, data
                        harvesting, data extracting or any other similar activity in
                        relation to this Website;
                    </li>
                    <li className="text-body-text text-info ">
                        <i className="ionicons ion-checkmark-round color-orange-900"/> Using this Website to engage in
                        any advertising or marketing.
                    </li>
                </ul>
                <p className="text-body-text color-gray-600 mt-20">Certain areas of this Website are restricted from
                    being accessed by you and Banduri may further
                    restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user
                    ID and password you may have for this Website are confidential and maintaining confidentiality
                    of your ID and password are solely your responsibility and not the responsibility of Banduri, its
                    employees, or its owners.</p>

                <h2 className="text-heading-3 color-gray-900 mt-20 mb-10">No warranties</h2>
                <p className="text-body-text color-gray-600 mt-20">This Website is provided "as is," with all faults,
                    and Banduri express no representations or
                    warranties, of any kind related to this Website or the materials contained on this Website. Also,
                    nothing contained on this Website shall be interpreted as advising you.</p>

                <h2 className="text-heading-3 color-gray-900 mt-20 mb-10">Limitation of liability</h2>
                <p className="text-body-text color-gray-600 mt-20">In no event shall Banduri, nor any of its officers,
                    directors and employees, shall be held liable for
                    anything arising out of or in any way connected with your use of this Website whether such liability
                    is under contract. Banduri, including its officers, directors and employees shall not be held liable
                    for any indirect, consequential or special liability arising out of or in any way related to your
                    use of this Website.</p>

                <h2 className="text-heading-3 color-gray-900 mt-20 mb-10">Indemnification</h2>
                <p className="text-body-text color-gray-600 mt-20">You hereby indemnify to the fullest extent Banduri
                    from and against any and/or all liabilities,
                    costs, demands, causes of action, damages and expenses arising in any way related to your breach of
                    any of the provisions of these Terms.</p>

                <h2 className="text-heading-3 color-gray-900 mt-20 mb-10">Severability</h2>
                <p className="text-body-text color-gray-600 mt-20">If any provision of these Terms is found to be
                    invalid under any applicable law, such provisions
                    shall be deleted without affecting the remaining provisions herein.</p>

                <h2 className="text-heading-3 color-gray-900 mt-20 mb-10">Variation of Terms</h2>
                <p className="text-body-text color-gray-600 mt-20">Banduri is permitted to revise these Terms at any
                    time as it sees fit, and by using this Website you
                    are expected to review these Terms on a regular basis.</p>

                <h2 className="text-heading-3 color-gray-900 mt-20 mb-10">Assignment</h2>
                <p className="text-body-text color-gray-600 mt-20">The Banduri is allowed to assign, transfer, and
                    subcontract its rights and/or obligations under these
                    Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any
                    of your rights and/or obligations under these Terms.</p>

                <h2 className="text-heading-3 color-gray-900 mt-20 mb-10">Entire Agreement</h2>
                <p className="text-body-text color-gray-600 mt-20">These Terms constitute the entire agreement between
                    Banduri and you in relation to your use of this
                    Website, and supersede all prior agreements and understandings.</p>

                <h2 className="text-heading-3 color-gray-900 mt-20 mb-10">Governing Law & Jurisdiction</h2>
                <p className="text-body-text color-gray-600 mt-20">These Terms will be governed by and interpreted in
                    accordance with the laws of the Commonwealth of
                    Virginia, and you submit to the non-exclusive jurisdiction of the state and federal courts located
                    in the Commonwealth of Virginia for the resolution of any disputes.</p>
            </div>
            <Footer/>
        </div>
    );
}

export default Terms;