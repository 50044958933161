import React from 'react';
import P2 from '../img/page/homepage1/img-1-5.jpg';
import P1 from '../img/bma_sculpture_glass.jpg';


function Subscribe() {
    const url = 'https://banduri.us4.list-manage.com/subscribe/post?u=da5e362853867719883eda526&amp;id=581804fe5d&amp;f_id=001f55e8f0';


    return (

        <section className="section-box overflow-visible mb-60 align-center" id="subscribe">
            <div className="container mt-60">
                <div className="row">

                    <div className="col-lg-10 col-sm-offset-1 mx-auto">
                        <div className="bg-7 box-newsletter position-relative">
                            <div className="row">
                                <div className="col-lg-5 col-md-7">
                                    <span
                                        className="text-body-capitalized color-gray-500 text-uppercase">Insights straight to your inbox</span>
                                    <h4 className="text-heading-2 color-banduri-blue-900 mb-10 mt-10">
                                        Subscribe to our newsletter
                                    </h4>

                                    <div className="box-form-newsletter mt-30">
                                        <div id="mc_embed_signup">
                                            <form
                                                action={url}
                                                method="post" id="mc-embedded-subscribe-form"
                                                name="mc-embedded-subscribe-form"
                                                className="validate" target="_blank" noValidate>
                                                <div className="form-newsletter mc-field-group"
                                                >
                                                    <input className="input-newsletter "
                                                           id="mce-FNAME"
                                                           type="text"
                                                           name="FNAME"
                                                           placeholder="Enter your full name"/>
                                                    <input className="input-newsletter "
                                                           id="mce-EMAIL"
                                                           type="email"
                                                           name="EMAIL"
                                                           placeholder="Enter your email address"/>
                                                </div>
                                                <div className="col-lg-12 mt-5">
                                                    <button className="btn btn-black icon-arrow-right-white mr-40 mb-20"
                                                            type="submit">Subscribe
                                                    </button>
                                                    <br className="d-lg-none d-block"/><span
                                                    className="text-body-text-md color-gray-500 mb-4">By clicking the Subscribe button, you agree to our
                                                                           <a href="/terms"> Terms and Conditions.</a></span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-lg-7 col-md-6 mt-20 mt-lg-0 mt-md-40 mt-sm-20 position-relative align-center text-end">
                                    <img className="img-responsive img-rounded" src={P1} alt="Banduri"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    );
}

export default Subscribe;