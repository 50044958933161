import React from "react";
import './css/styles.css';
import './css/bootstrap.css';
import './css/flexslider.css';
import './css/queries.css';
import './css/animate.css';
import './css/argonstyles.css';


import './css/ionicons.min.css';
import './css/preloader.css';
import './css/responsive.css';
import './css/globals.css';
import './css/Home.module.css';
import './fonts/fontawesome-webfont.svg';
import './fonts/glyphicons-halflings-regular.svg';
import './fonts/ionicons.svg';
import './fonts/ionicons.eot';
import './fonts/ionicons.ttf';
import './fonts/ionicons.woff';
import './fonts/uicons/uicons-regular-rounded.eot';
import './fonts/uicons/uicons-regular-rounded.woff';
import './fonts/uicons/uicons-regular-rounded.woff2';
import './css/modal.css';
import './css/swiper-custom.css';


//***APP JS
function App() {
    return (
        <div className="App">

        </div>
    )
}


export default App;

