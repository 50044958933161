import React from 'react';
import IntHeader from "../elements/IntHeader";
import Footer from "../elements/Footer";
import About from "../elements/About";
import Contact from "../elements/Contact";
import ReactGA from 'react-ga';

function Team() {
    ReactGA.initialize('UA-148043567-1');
    ReactGA.pageview('/contact');
    ReactGA.event({
        category: 'Visitor',
        action: 'view team'
    });
    return (
        <div id="Team">
            <IntHeader/>
            <About/>
            <Footer/>
        </div>
    );
}

export default Team;