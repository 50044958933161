import React from 'react';
import P1 from '../img/GSA_nav-logo.png';
import P2 from '../img/seaport-nxtgen.png';
import P3 from '../img/nitacc.png';
import CapStmnt from '../cap_stmnt/Banduri_CapStatement_DEC22.pdf';
import Accordion from "./Accordion";

function Capabilities() {
    return (
        <div>
            <section className="section-box">
                <div className="banner-hero banner-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <h1 className="text-display-2 color-banduri-blue-900">
                                    Great partnerships start with
                                    <span className="color-orange-900"> trust</span>
                                </h1>
                                <p className="text-body-lead-large color-gray-500 mt-10 pr-40">
                                    Banduri has proven performance in complex digital transformations.
                                </p>
                                <div className="mt-20">

                                <a href={CapStmnt} className="btn btn-black icon-arrow-right-white mb-20">Download our
                                    Capabilities Statement
                                </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-box mt-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-sm-1 col-12"/>
                        <div className="col-lg-8 col-sm-10 col-12 text-center mt-40">
                            <h2 className="text-heading-1 color-banduri-blue-900 mb-10">Proven Performance.<br
                                className="d-lg-block d-none"/>Continuous Innovation.</h2>
                            <p className="text-body-lead-large color-gray-600 mt-20">Banduri is a 100% SBA-Certified
                                Economically Disadvantaged Women-Owned Small Business. Our services can be accessed
                                through several contract vehicles as a prime and preferred subcontractor.</p>
                        </div>
                        <div className="col-lg-2 col-sm-1 col-12"/>
                    </div>
                </div>
                <div className="container mt-20 mb-20">
                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <div className="bg-2 box-square hover-up">
                                <h4 className="text-heading-4 color-banduri-blue-900 mb-15">GSA MAS</h4>
                                <p className="text-body-text-md color-gray-600"> Banduri is a registered contract holder
                                    and offers a range of IT, Cloud and Cybersecurity Engineering, and
                                    integrated training and facilitation services for Agile, Lean
                                    Portfolio Management, and Innovation Strategy as a prime contractor on the
                                    GSA MAS schedule.</p>
                                <div className="box-image-inner bg-color-3"><img src={P1} alt="Banduri"/></div>
                                <p className=" mt-5 color-gray-900">Link to GSA: <a
                                    className="color-orange-900"
                                    href="https://www.gsaelibrary.gsa.gov/ElibMain/contractorInfo.do?contractNumber=47QTCA22D00D5&contractorName=BANDURI+LLC&executeQuery=YES">Banduri
                                    GSA Profile</a></p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="bg-6 box-square hover-up">
                                <h4 className="text-heading-4 color-banduri-blue-900 mb-15">SeaPort NextGen</h4>
                                <p className="text-body-text-md color-gray-600"> Banduri is a registered subcontractor
                                    providing IT, Cloud, and Cybersecurity
                                    engineering services
                                    as well as integrated training and facilitation services for Agile, Lean
                                    Portfolio Management, and Innovation Strategy.</p>
                                <div className="box-image-inner bg-color-3"><img src={P2} alt="Banduri"/></div>
                            </div>

                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="bg-8 box-square hover-up">
                                <h4 className="text-heading-4 color-banduri-blue-900 mb-15">CIO-SP3</h4>
                                <p className="text-body-text-md color-gray-600">As a subcontractor on the NITACC CIO-SP3
                                    vehicle, Banduri was responsible
                                    for leading the Marine Corps Business Mission Area (BMA) modernization
                                    effort. CIO-SP3 advantages include pricing similar to GSA
                                    Services, best value criteria for selection, and easy modification to task or
                                    delivery orders.</p>
                                <div className="box-image-inner bg-color-3"><img src={P3} alt="Banduri"/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-box mt-10">
                <div className="container mt-30">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-4 mb-20">
                                    <h4 className="text-heading-6 icon-leaf color-banduri-blue-900">People First</h4>
                                    <p className="text-body-excerpt color-gray-600 mt-15">We apply Human-Centered Design
                                        to every task so that together we can solve problems that matter.</p>
                                </div>
                                <div className="col-lg-4 mb-20">
                                    <h4 className="text-heading-6 icon-leaf color-banduri-blue-900">Agile Approach</h4>
                                    <p className="text-body-excerpt color-gray-600 mt-15">Change
                                        Architecture<sup>®</sup> let's you drive change and accelerate continuous
                                        innovation.
                                    </p>
                                </div>
                                <div className="col-lg-4 mb-20 ">
                                    <h4 className="text-heading-6 icon-leaf color-banduri-blue-900">Value Driven</h4>
                                    <p className="text-body-excerpt color-gray-600 mt-15">We align people, processes,
                                        and technology in your organization to deliver value that counts.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="row  bg-5 mt-20">
                                    <div className="col-lg-2 col-sm-1 col-12"/>
                                    <div className="col-lg-8 col-sm-10 col-12 text-center">
                                        <h2 className="text-heading-3 color-banduri-blue-900 mt-20">Frequently asked
                                            questions</h2>
                                        <p className="text-body-lead-large color-gray-600 mt-10">Click the questions
                                            below to get
                                            answers to some of the FAQs for working with us.</p>
                                    </div>
                                    <div className="col-lg-2 col-sm-1 col-12"/>
                                </div>

                                <div className="col-lg-12 mt-30">
                                    <Accordion/>
                                    <div className="mt-20">
                                        <a href="mailto:contracts@banduri.com"
                                           className="btn btn-green-900 icon-arrow-right-white text-heading-6 color-white">Contact
                                            Contracts</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1"/>
                    </div>
                </div>

            </section>
            {/* <section className="section-box ">
                <div className="container mt-20">
                    <div className="bdrd-58  bg-2">
                        <div className="row">
                            <div className="col-lg-2 col-sm-1 col-8 text-center"/>
                            <div className="col-lg-8 col-sm-10 col-12 text-center">
                                <h2 className="text-heading-1 color-gray-900 mt-30">
                                    Contract Vehicles
                                </h2>
                                <p className="text-body-lead-large color-gray-600 mt-5">
                                    Banduri has proven performance leading Digital Transformation.
                                </p>
                            </div>
                            <div className="col-lg-1 "/>
                        </div>
                        <div className="container mt-20">
                            <div className="row">
                                <div className="col-lg-11 col-md-8 col-sm-4 mt-20">
                                    <div className="card-grid-style-2 hover-up mb-5">
                                        <div className="img-user">
                                            <img src={P1} alt="Banduri"/>
                                        </div>
                                        <h5 className="text-heading-5 color-orange-900">
                                            GSA Multiple Award Schedule (MAS)
                                        </h5>
                                        <p className="text-body-text color-gray-600 mt5">
                                            Banduri offers a range of IT, Cloud, and Cybersecurity engineering services
                                            as
                                            well as integrated training and facilitation services for Agile, Lean
                                            Portfolio Management, and Innovation Strategy as a prime contractor on the
                                            GSA MAS schedule.
                                            Advantages of the GSA MAS include:</p>

                                        <p className="contracts">
                                            <ul>
                                                <li className="text-body-text color-gray-600"><strong>Savings</strong> –
                                                    Competitive
                                                    market-based pricing that
                                                    leverages the
                                                    buying power of the federal government, with the ability to
                                                    negotiate further
                                                    discounts at the order level.
                                                </li>
                                                <li className="text-body-text color-gray-600">
                                                    <strong>Flexibility</strong> and
                                                    choice – The ability to tailor
                                                    orders to get
                                                    what you need by customizing terms and conditions at the order
                                                    level.
                                                </li>

                                                <li className="text-body-text color-gray-600">
                                                    <strong>Efficiency</strong> due to:
                                                    <ul>
                                                        <li className="text-body-text color-gray-600 ionicons ion-ios7-star">Shorter
                                                            lead time
                                                        </li>
                                                        <li className="text-body-text color-gray-600 ionicons ion-ios7-star">Transparency</li>
                                                        <li className="text-body-text color-gray-600 ionicons ion-ios7-star ">Control
                                                            of the procurement
                                                        </li>
                                                        <li className="ctext-body-text color-gray-600 ionicons ion-ios7-star">Lower
                                                            administrative costs
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </p>

                                        <h4 className="text-body-lead color-gray-900 mb-5">
                                            <p><i className="ionicons ion-ios7-star text-info"/> Ordering Contact
                                                Information
                                            </p>
                                        </h4>
                                        <p className="text-body-text-md">
                                            <ul>
                                                <p>
                                                    <i className="ionicons ion-android-call  text-info"/> 540-699-0805
                                                </p>
                                                <p>
                                                    <i className="ionicons ion-ios7-location  text-info"/> 400
                                                    Charles Street
                                                    Fredericksburg VA 22401
                                                </p>
                                                <p>
                                                    <i className="ionicons ion-android-mail  text-info"/> <a
                                                    href="mailto:contracts@banduri.com"> contracts@banduri.com</a>
                                                </p>

                                                <h5 classname="color-orange-900"><p>To order online please use <a
                                                    href="https://www.ebuy.gsa.gov/ebuy/">GSA
                                                    eBuy</a></p></h5>
                                            </ul>
                                        </p>

                                    </div>
                                </div>
                                <div className="col-lg-11 col-md-8 col-sm-4 mt-20">
                                    <div className="card-grid-style-2 hover-up mb-5">
                                        <div className="img-user">
                                            <img src={P2} alt="Banduri"/>
                                        </div>
                                        <h5 className="text-heading-5 color-orange-900">
                                            Navy Seaport NextGen (NxG)
                                        </h5>
                                        <p className="text-body-text color-gray-600 mt-5">
                                            Banduri is a registered subcontractr procvidign IT, Cloud, and Cybersecurity
                                            engineering services
                                            as well as integrated training and facilitation services for Agile, Lean
                                            Portfolio Management, and Innovation Strategy. Seaport-NextGen offers
                                            customers:</p>
                                        <p className="contracts">
                                            <ul>
                                                <li className="text-body-text color-gray-600"><strong>Focus</strong> –
                                                    on set asides has resulted in nearly 85% of its contract–holders
                                                    being small businesses.
                                                </li>
                                                <li className="text-body-text color-gray-600">
                                                    <strong>Diverse</strong> population of large and small companies
                                                    with a wide range of IT and professional service offerings.
                                                </li>

                                                <li className="text-body-text color-gray-600">
                                                    <strong>Standardized</strong> solicitation and ordering makes
                                                    procuring services easy and efficient.

                                                </li>
                                            </ul>
                                        </p>

                                        <h4 className="text-body-lead color-gray-900 mb-5">
                                            <p><i className="ionicons ion-ios7-star text-info"/> Small Business Set
                                                Aside Inquiries
                                            </p>
                                        </h4>
                                        <p className="text-body-text-md">
                                            <ul>
                                                <p>
                                                    <i className="ionicons ion-android-call  text-info"/> 540-699-0805
                                                </p>
                                                <p>
                                                    <i className="ionicons ion-ios7-location  text-info"/> 400
                                                    Charles Street
                                                    Fredericksburg VA 22401
                                                </p>
                                                <p>
                                                    <i className="ionicons ion-android-mail  text-info"/> <a
                                                    href="mailto:contracts@banduri.com"> contracts@banduri.com</a>
                                                </p>
                                            </ul>
                                        </p>
                                    </div>
                                </div>

                                <div className="col-lg-11 col-md-8 col-sm-4 mt-20">
                                    <div className="card-grid-style-2 hover-up mb-5">
                                        <div className="img-user">
                                            <img src={P3} alt="Banduri"/>
                                        </div>
                                        <h5 className="text-heading-5 color-orange-900">
                                            NIH CIO-SP3 IT Services/Solutions
                                        </h5>
                                        <p className="text-body-text color-gray-600 mt-5">
                                            As a subcontractor on the NITACC CIO-SP3 vehicle, Banduri was responsible
                                            for leading the Marine Corps Business Mission Area (BMA) modernization
                                            effort. CIO-SP3 advantages include:

                                            Product pricing is lower than open market, less than or equal to GSA
                                            Services are at competitive, pre-competed prices that can be negotiated
                                            lower
                                            Customer can select awardees based on their best value criteria
                                            Customized terms and conditions relevant to your task or delivery order can
                                            be easily added
                                            No special "Delegation of Procurement Authority" is needed from NITAAC to
                                            use our GWACs or online system
                                            Two levels of contract competition for the greatest savings
                                            No need to synopsize or post requirements according to FAR Subpart 16.505
                                            Use NITAAC's Electronic Government Ordering System (e-GOS) to find labor
                                            rates for faster and easier market research</p>

                                        <p className="contracts">
                                            <ul>
                                                <li className="text-body-text color-gray-600"><strong>Product
                                                    Pricing</strong> –
                                                    is lower than open market, less than or equal to GSA
                                                </li>
                                                <li className="text-body-text color-gray-600">
                                                    <strong>Services</strong> are at competitive, pre-competed prices
                                                    that can be negotiated lower.
                                                </li>
                                                <li className="text-body-text color-gray-600">
                                                    <strong>Best value</strong> criteria are defined but the customer.
                                                </li>
                                                <li className="text-body-text color-gray-600">
                                                    <strong>Simplified</strong> procurement through the eGOS system with
                                                    no "Delegation of Procurement Authority" required.
                                                </li>
                                            </ul>
                                        </p>
                                        <h4 className="text-body-lead color-gray-900 mb-5">
                                            <p><i className="ionicons ion-ios7-star text-info"/> Partnership Inquiries
                                            </p>
                                        </h4>
                                        <p className="text-body-text-md">
                                            <ul>
                                                <p>
                                                    <i className="ionicons ion-android-call  text-info"/> 540-699-0805
                                                </p>
                                                <p>
                                                    <i className="ionicons ion-ios7-location  text-info"/> 400
                                                    Charles Street
                                                    Fredericksburg VA 22401
                                                </p>
                                                <p>
                                                    <i className="ionicons ion-android-mail  text-info"/> <a
                                                    href="mailto:contracts@banduri.com"> contracts@banduri.com</a>
                                                </p>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>*/}
            {/*  <section className="portfolio text-center section-padding" id="portfolio">
                <div className="container">
                    <div className="row">
                        <div id="portfolioSlider">
                            <ul className="slides">
                                <div className="col-md-4 wp4">
                                    <div className="overlay-effect effects clearfix">
                                        <div className="img">
                                            <img src={P1} alt="Portfolio Item"/>
                                            <div className="overlay">
                                                <a href="https://banduri.typeform.com/to/tAMHA9" className="expand"><i
                                                    className="fa fa-search"/><br/>Learn
                                                    More</a>
                                                <a className="close-overlay hidden">x</a>
                                            </div>
                                        </div>
                                    </div>
                                    <h2>Strategic <br/> Design</h2>
                                    <p>Strategic design applies futures-thinking and foresight principles to increase an
                                        organization’s ability to <em>continuously innovate</em>.</p>
                                </div>

                                <div className="col-md-4 wp4">
                                    <div className="overlay-effect effects clearfix">
                                        <div className="img">
                                            <img src={P2} alt="Portfolio Item"/>
                                            <div className="overlay">
                                                <a href="https://banduri.typeform.com/to/XCMc0D" className="expand"><i
                                                    className="fa fa-search"/><br/>Learn
                                                    More</a>
                                                <a className="close-overlay hidden">x</a>
                                            </div>
                                        </div>
                                    </div>
                                    <h2>Organizational Design</h2>
                                    <p>Using applied Design Thinking and Service Design, we work with organizations to
                                        quickly identify a model for operations that <em>optimizes for value</em>.</p>
                                </div>

                                <div className="col-md-4 wp4 delay-1s">
                                    <div className="overlay-effect effects clearfix">
                                        <div className="img">
                                            <img src={P3} alt="Portfolio Item"/>
                                            <div className="overlay">
                                                <a href="https://banduri.typeform.com/to/IqHxNn" className="expand"><i
                                                    className="fa fa-search"/><br/>Learn
                                                    More</a>
                                                <a className="close-overlay hidden">x</a>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className="text-center">Product<br/>  Design</h2>
                                    <p>We help organizations design products and services that <em>people want to
                                        use</em> because
                                        they solve problems that matter.</p>
                                </div>

                            </ul>
                        </div>
                    </div>
                </div>
            </section>*/}
        </div>
    );
}


export default Capabilities;
