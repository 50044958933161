import React from 'react';

function Footer() {
    return (

        <footer className="footer mt-50">
            <section className="section-box">
                <div className="banner-hero banner-breadcrums bg-gray-100">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-lg-12">
                                <p className="text-heading-6 color-banduri-blue-900 mb-20">Banduri is a 100% SBA
                                    Certified
                                    <br className="d-lg-block d-none"/>Economically Disadvantaged Women-Owned Small
                                    Business
                                    (EDWOSB)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container">

                <div className="row">
                    <div className="col-lg-3 width-20 mb-30">
                        <h4 className="text-heading-5 mt-10">Contact</h4>
                        <div className="mt-20 text-body-text color-white-600"><i className="fa fa-map-marker"/> 400
                            Charles
                            Street Fredericksburg VA 22401
                        </div>
                        <div className="mt-10 text-body-text color-white-600"><i className="fa fa-mobile"/> (540)
                            699-0805
                        </div>
                        <div className="mt-10 text-body-text color-white-600"><i className="fa fa-envelope "/> <a
                            href=" mailto:hello@banduri.com"> hello@banduri.com</a></div>
                    </div>
                    <div className="col-lg-3 width-20 mb-30">
                        <h4 className="text-heading-5 mt-10">About Us</h4>
                        <ul className="menu-footer mt-10">
                            <li><a href="/Team">Team</a></li>
                            <li><a href="/Jobs">Careers</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 width-20 mb-30">
                        <h4 className="text-heading-5 mt-10">Useful links</h4>
                        <ul className="menu-footer mt-10">
                            <li><a href="./">How We Work</a></li>
                            <li><a href="/Contracts">Request a Quote</a></li>
                            <li><a href="/Contact">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-bottom mt-30">
                    <div className="row">
                        <div className="col-md-6"><span
                            className="color-gray-400 text-body-lead">&copy; Banduri LLC 2023</span><a
                            className="text-body-text color-white-400 ml-50" href="/Terms">Terms and Conditions</a>
                        </div>
                        <div className="col-md-6 text-center text-lg-end text-md-end">
                            <div className="footer-social">
                                <a className="icon-socials icon-facebook" href="https://www.facebook.com/banduristrong/"
                                   target="_blank"></a>
                                <a className="icon-socials icon-twitter" href="https://twitter.com/banduricreative"
                                   target="_blank"></a>
                                <a className="icon-socials icon-instagram"
                                   href="https://www.instagram.com/banduricreative/"
                                   target="_blank"></a>
                                <a className="icon-socials icon-linkedin"
                                   href="https://www.linkedin.com/company/banduri/"
                                   target="_blank"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>


    );
}

export default Footer;
