import React from 'react';
import IntHeader from "./elements/IntHeader";
import ErrorPage from "./pages/404";
import Contact from "./elements/Contact";
import Footer from "./elements/Footer";

const NotFound = () => {
    return (
        <div id="NotFound">
            <IntHeader/>
            <ErrorPage/>
            <Contact/>
            <Footer/>
        </div>
    );
};

export default NotFound;