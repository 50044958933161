import React from 'react';
import IntHeader from "../elements/IntHeader";
import Footer from "../elements/Footer";
import Subscribe from "../elements/Subscribe";
import Contact from "../elements/Contact";
import ReactGA from 'react-ga';
import JobListing from "../elements/JobListing";

function Jobs() {
    ReactGA.initialize('UA-148043567-1');
    ReactGA.pageview('/jobs');
    ReactGA.event({
        category: 'Visitor',
        action: 'View jobs'
    });
    return (
        <div id="Jobs">
            <IntHeader/>
            <JobListing/>
            <Subscribe/>
            <Footer/>
        </div>

    );
}

export default Jobs;